/** @format */



import React, { useEffect, useState } from "react";

import FullLayout from "../../Layout/FullLayout";

import { Box } from "@mui/system";

import {

  Card,

  CardContent,

  Container,

  Grid,

  Divider,

  TextField,

  Button,

  MenuItem,

  TextareaAutosize,

} from "@mui/material";

import { Typography, Select } from "@mui/material";

import { styled } from "@mui/system";

import BirthDatePicker from "../common/BirthDatePicker";

import { useForm, Controller } from "react-hook-form";

import { useAuth } from "../common/UseAuth";

import { get } from "lodash";

import getConfigObject from "../Util/Config";

import axios from "axios";

import { showSuccessToast, showFailureToast } from "../common/Toaster";


const FieldTitle = styled("div")({

  marginBottom: 8,

  marginTop: 8,

  fontFamily: "Rubik, san-serif",

});



const defaultValues = {

  loginId: "",

  title: "",

  firstName: "",

  lastName: "",

  dateOfBirth: "",

  homePhone: "",

  mobilePhone: "",

  officePhone: "",

  officeName: "",

  assistantPhone: "",

  email: "",

  fax: "",

  location: "",

  referrerPreferredImplant: "",

  referrerPreferredLab: "",

};



const MyInfo = () => {

  const { user } = useAuth();

  const [roleId, setroleId] = useState([]);

  const hospitalId = JSON.parse(localStorage.getItem("app:userInfo")).hospitalId;

  const {

    handleSubmit,

    control,

    reset,

    formState: { errors },

  } = useForm({ defaultValues });



  const onSubmit = async (data) => {

    const newData = {
      ...data, // Spread existing data fields
      hospitalId: hospitalId, // Append hospitalId state
    };

    saveMyInfo(newData);

  };



  const fetchMyInfo = async () => {

    try {

      if (!user?.id) return;

      const { id } = user;

      setroleId(user.roleId);

      const userConfig = getConfigObject("get", `user/${id}`);

      const userResult = await axios(userConfig);

      const userRecords = get(userResult, ["data", "data", "0", "0"], {});

      reset(userRecords);

    } catch (err) {

      showFailureToast("failed to fetch patients record!.");

      console.error("failed to fetch patients record", err);

    }

  };


  const saveMyInfo = async (formData) => {

    try {

      if (!user?.id) return;

      const { id } = user;

      const userConfig = getConfigObject("post", `user/${id}`, formData);

      await axios(userConfig);

      showSuccessToast("Details are updated successfully.");

    } catch (err) {

      showFailureToast("Failed to update details!.");

      console.error("failed to fetch patients record", err);

    }

    fetchMyInfo();

  };

  useEffect(() => {

    fetchMyInfo();

  }, []);

  return (

    <FullLayout>

      <Box

        component="main"

        sx={{

          flexGrow: 1,

        }}

      >

        <Container maxWidth="lg">

          <Typography sx={{ mb: 3 }} variant="h4">

            Profile

          </Typography>

          <Card sx={{ boxShadow: "rgb(0 0 0 / 28%) 0px 23px 45px" }}>

            <CardContent >

              <form onSubmit={handleSubmit(onSubmit)}>

                <Box

                  sx={{

                    display: "flex",

                    alignItems: "center",

                    p: "15px 30px",

                  }}

                >

                  <Typography variant="h6">Personal Info</Typography>

                </Box>

                <Divider />

                <Box sx={{ p: "15px 30px" }}>

                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={12}>

                      <FieldTitle>Practice/Doctor name:</FieldTitle>

                      <Controller

                        control={control}

                        name="lastName"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            name="lastName"

                            fullWidth

                            disabled={roleId != 3}

                            value={value}

                            onChange={onChange}

                            type="search"

                          />

                        )}

                      />



                    </Grid>



                  </Grid>



                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>User Id:</FieldTitle>

                      <Controller

                        control={control}

                        name="loginId"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            disabled

                            name="loginId"

                            fullWidth

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                    </Grid>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Password:</FieldTitle>

                      <Controller

                        control={control}

                        name="password"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            type={"password"}

                            name="password"

                            fullWidth

                            value={value}

                            onChange={onChange}



                          />

                        )}

                      />

                    </Grid>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Repeat Password:</FieldTitle>

                      <Controller

                        control={control}

                        name="repeatPassword"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            fullWidth

                            name="repeatPassword"

                            type={"password"}

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                    </Grid>

                  </Grid>



                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Phone:</FieldTitle>

                      <Controller

                        control={control}

                        name="officePhone"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            name="officePhone"

                            fullWidth

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                    </Grid>



                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Email:</FieldTitle>

                      <Controller

                        control={control}

                        name="email"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            name="email"

                            fullWidth

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                    </Grid>

                  </Grid>

                  <Box mt={3}>

                    <Button

                      fullWidth

                      variant="contained"

                      type="submit"

                      sx={{ textTransform: "capitalize", p: 1 }}

                    >

                      Submit Information

                    </Button>

                  </Box>

                </Box>

              </form>

            </CardContent>

          </Card>

        </Container>

      </Box>

    </FullLayout >

  );

};



export default MyInfo;

