/** @format */

import { Typography, Button } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
const Error = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h1" textAlign="center">
        404: The page you are looking for isn’t here
      </Typography>
      <Typography textAlign="center">
        You either tried some shady route or you came here by mistake. Whichever
        it is, try using the navigation
      </Typography>
      <Box sx={{ width: "65%", margin: "18px auto" }}>
        <img
          src="https://material-kit-react.devias.io/static/images/undraw_page_not_found_su7k.svg"
          alt="error"
          width="100%"
        />
      </Box>

      <Link href="/" sx={{ textDecoration: "none" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" startIcon={<ArrowBackIcon />}>
            Go Back To Dashboard
          </Button>
        </Box>
      </Link>
    </Container>
  );
};

export default Error;
