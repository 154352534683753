import React, { useEffect, useRef, useState } from "react";
import useFileUpload from "react-use-file-upload";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
const Input = styled("input")({
  display: "none",
});

const UploadFiles = ({description}) => {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();
  const [imageName, setImageName] = useState([]);
  const fileArr = [];
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = createFormData();
    formData.forEach((f) => console.log("in forEach", f));
    console.log("all the files queued for upload===>>>>", formData);
  };


  const clearQueue = () => {
    clearAllFiles()
    setImageName([])
  }
  const uploadFileHandler = (event) => {
    debugger
    setFiles(event.target.files);
   };
  const handleName = () => {
  
    inputRef.current.click()
    debugger
    if (fileNames.length == 0) return [] 
    if(fileNames.length == 1){
      setImageName(fileNames == undefined ? [] : [fileNames[fileNames.length - 1] +'_'+description])
    }
    setImageName([...imageName,fileNames[fileNames.length - 1] +'_'+description])

  }
  return (
    <>
      <div>
        <div>
          {/* Display the files to be uploaded */}
          <div>
            <ul>
              {
              imageName.map((name) => ( 
                <li key={name}>
                  <span>{name}</span>
                  
                  <span onClick={() => removeFile(name)}>
                    <i className="fa fa-times">❌</i>
                  </span>
                </li>
              ))}
            </ul>
            {files.length > 0 && (
              <button onClick={() =>clearQueue()}>Clear All</button>
            )}
          </div>
        </div>

        <label htmlFor="contained-button-file">
        <input type="file" multiple onChange={uploadFileHandler}/>
        </label>
      </div>
    </>
  );
};

export default UploadFiles;
