const getConfigObject = (method, url, data, type) => {

    //const serverUrl = `http://localhost:3000/api/v1/${url}`;

    const serverUrl = `https://mypatientstatus.com/api/v1/${url}`;

    return {

        "method": method || 'post',

        "url": serverUrl,

        "headers": {

            'Content-Type': type || "application/json"

        },

        "data": data || {}

    }

};



export default getConfigObject;