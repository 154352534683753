import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'; // For navigation
import styles from '../NavbarLanding.module.css'; // Import the CSS file
import logo from '../../../assets/images/msplogo.png'; // Adjust path as needed

function NavbarLanding({ setCurrentPage }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const reload = () => {
        // Trigger a resize event on component mount
        window.dispatchEvent(new Event('resize'));
    };

    const scrollToSection = (event, sectionId, page) => {
        if (page !== "contact") {
            setCurrentPage(page); // Set current page here
            closeMenu();
        }

        event.preventDefault();
        const navbarHeight = document.querySelector(`.${styles.navbarr}`).offsetHeight;
        const targetElement = document.querySelector(sectionId);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - navbarHeight,
                behavior: 'smooth'
            });
            closeMenu(); // Close the menu after scrolling
        }

    };

    return (
        <>
            <nav className={styles.navbarr}>
                <div className={styles.logoContainerStyle}>
                    <a href="/" className={styles.logoLinkStyle}>
                        <img src={logo} alt="Company Logo" className={styles.logoStyle} />
                    </a>
                </div>
                <div className={styles.hamburgerMenuStyle} onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </div>
                <div className={styles.navOptionsStyle}>
                    <ul className={styles.ulStyle}>
                        <li><a href="/" className={styles.linkStyle} onClick={(e) => { e.preventDefault(); scrollToSection(e, '#splash', 'home'); }}>Home</a></li>
                        <li><a href="#solutions" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#solutions', 'home')}>Overview</a></li>
                        <li><a href="#about" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#about', 'about')}>About</a></li>
                        <li><a href="#pricing" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#pricing', 'pricing')}>Pricing</a></li>
                        <li><a href="#contact" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#contact', 'contact')}>Contact</a></li>
                        <li><RouterLink to="/login" className={styles.linkStyle} onClick={reload}>Login</RouterLink></li>
                    </ul>
                </div>
            </nav>
            {isMenuOpen && (
                <div className={styles.dropdownMenu}>
                    <ul className={styles.ulStyle}>
                        <li><a href="/" className={styles.linkStyle} onClick={(e) => { e.preventDefault(); scrollToSection(e, '#splash', 'home'); }}>Home</a></li>
                        <li><a href="#solutions" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#solutions', 'home')}>Overview</a></li>
                        <li><a href="#about" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#about', 'about')}>About</a></li>
                        <li><a href="#pricing" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#pricing', 'pricing')}>Pricing</a></li>
                        <li><a href="#contact" className={styles.linkStyle} onClick={(e) => scrollToSection(e, '#contact', 'contact')}>Contact</a></li>
                        <li><RouterLink to="/login" className={styles.linkStyle} onClick={reload}>Login</RouterLink></li>
                    </ul>
                </div>
            )}
        </>
    );
}

export default NavbarLanding;