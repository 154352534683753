import { Button, Card, CardContent, Container, InputLabel, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import FullLayout from "../../../../Layout/FullLayout";
import PdfForm from "./PdfForm";
import { Typography, Grid, Box, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

import getConfigObject from "../../../Util/Config";
import { get } from "lodash";
import axios from "axios";
import { useAuth } from "../../../common/UseAuth";
import { showSuccessToast, showFailureToast } from "../../../common/Toaster";
import LinearProgress from "@mui/material/LinearProgress";
import { Buffer } from "buffer";
import DocTable2 from "../../../common/DocTable2";
const Input = styled("input")({
  display: "none",
});
const NewPatient = () => {
  const [departmentData, setDepartmentData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const { user } = useAuth();
  const selectRef = useRef(null);
  const [initialHospitalQuestion, setInitialHospitalQuestion] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loader, setloader] = useState("0");
  const [pdfres, setdocumentId] = useState("");
  const [pdfContent, setPdfContent] = useState("");
  const referralForm = useForm();
  const [fileurl, setfileurl] = useState("");
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = referralForm;

  const containerdiv = {
    width: "95%",
    margin: "auto",
  };
  const departmentValue = watch("department");
  const referToValue = watch("referTo");

  const resetComponentState = () => {
    reset();
    setInitialHospitalQuestion([]);
    setDoctorData([]);
  };

  const fetchDoctorData = async () => {
    try {
      if (!user?.hospitalId) return;
      const { hospitalId } = user;
      const doctorConfig = getConfigObject("post", "deparments/hospital", { hospitalId });
      const doctorsResult = await axios(doctorConfig);
      const departmentRecords = get(doctorsResult, ["data", "data", "0", "0", "depatmentDoctor"], []);
      const deparments = JSON.parse(departmentRecords);
      setDepartmentData(deparments);
      setInitialHospitalQuestion([]);
    } catch (err) {
      showFailureToast("failed to fetch doctors record");
      console.error("failed to fetch doctors record", err);
    }
  };

  const fetchQuestions = async () => {
    if (!user?.hospitalId) return;
    const { hospitalId } = user;
    const departmentId = departmentValue;
    const doctorId = referToValue;
    setInitialHospitalQuestion([]);
    try {
      const hospitalQuestionConfig = getConfigObject("post", "questions/department/question", { hospitalId, departmentId, doctorId });
      const questionResult = await axios(hospitalQuestionConfig);
      const questionRecords = get(questionResult, ["data", "data", "0"], []);
      //debugger;
      setInitialHospitalQuestion(questionRecords);
    } catch (err) {
      showFailureToast("failed to fetch Question Type for doctors");
      console.error(`Failed to fetch Question Type for doctor's error ${err}`);
    }
  };

  const fetchDoctors = async () => {
    const doctors = get(
      departmentData?.find((department) => department?.id === departmentValue),
      "doc",
      []
    );
    setDoctorData(doctors);
    setInitialHospitalQuestion([]);
    setValue("referTo", null);
  };

  const fetchPdfUrl = async () => {
    setloader(1);
    if (!user?.hospitalId) return;
    const { hospitalId } = user;
    const departmentId = departmentValue;
    const doctorId = referToValue;
    const id = user?.id;

    setPdfUrl("");
    try {
      const hospitalPDFRecord = getConfigObject("post", "questions/pdf", { hospitalId, departmentId, doctorId, id });
      const pdfUrl2 = await axios(hospitalPDFRecord);
      debugger;
      // const pdfUrl = get(await axios(hospitalPDFRecord), ["data","data", "0", "0"], "");
      setPdfUrl(pdfUrl2.data.data == null ? "" : pdfUrl2.data.data.url);
      setdocumentId(pdfUrl2.data.data == null ? "" : pdfUrl2.data.data);
      setloader(0);
    } catch (err) {
      showFailureToast("failed to fetch Pdf for Type for doctor");
      console.error(`failed to fetch Pdf for Type for doctor's error ${err}`);
    }
  };
  const [fileAndDesc, setFileAndDesc] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const saveFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const callbackPdfContent = async (pdfBytes, pdfContent) => {
    console.log(pdfBytes);
    const encodedContent = Buffer.from(pdfBytes).toString("base64");
    if (!user?.hospitalId) return;
    const { hospitalId } = user;
    const departmentId = departmentValue;
    const doctorId = referToValue;
    setPdfUrl("");
    const formData = new FormData();
    try {
      if (selectedFiles?.length > 0) {
        for (let file of selectedFiles) {
          formData.append("refDocs", file);
        }
      }

      const stringPdfContent = JSON.stringify(pdfContent);

      const hospitalQuestionConfig = getConfigObject("post", "questions/pdf/create", {
        hospitalId,
        departmentId,
        doctorId,
        fileContent: encodedContent,
        stringPdfContent,
      });
      console.log(hospitalQuestionConfig);
      debugger;
      const questionResult = await axios(hospitalQuestionConfig);
      const questionRecords = get(questionResult, ["data", "data", "0", "0"], "");
      const patientId = get(questionResult, ["data", "data", "0", "1"], "");
      debugger;
      if (questionRecords) {
        formData.append("referralId", questionRecords.id);
        formData.append("patientId", patientId.pId);
        const contentType = "multipart/form-data";
        const documentUploadConfig = getConfigObject("post", "questions/documents/upload", formData, contentType);
        const docResult = await axios(documentUploadConfig);
      }
      setPdfUrl(questionRecords == null ? "" : questionRecords);

      resetComponentState();
      showSuccessToast("Patient referral details saved successfully");
    } catch (err) {
      showFailureToast("failed to post Failure");
      console.error(`failed to fetch Pdf for Type for doctor's error ${err}`);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      await Promise.all([fetchDoctorData()]);
    };
    loadInitialData();
  }, []);

  useEffect(() => {
    fetchDoctors();
  }, [departmentValue]);

  useEffect(() => {
    if (!isNaN(referToValue) && referToValue) {
      // fetchQuestions();
      fetchPdfUrl();
    }
  }, [referToValue]);

  const submitFile = async (e) => {
    const formData = new FormData();
    let filesize = 0;

    if (selectedFiles?.length > 0) {
      for (let file of selectedFiles) {
        if (file.size > 5374640) {
          showFailureToast("Please upload a file smaller than 5 MB");
          filesize = 1;
          e.preventDefault();
          return;
        }
        formData.append("refDocs", file);
      }
    }
    if (!user?.hospitalId) return;
    const { hospitalId } = user;
    const departmentId = departmentValue;
    const doctorId = referToValue;
    setPdfUrl("");
    try {
      if (filesize == 0) {
        formData.append("referralId", pdfres.referralId);
        formData.append("patientId", pdfres.patientId);
        formData.append("documentId", pdfres.documentId);
        formData.append("fileurl", fileurl);
        const contentType = "multipart/form-data";
        const documentUploadConfig = getConfigObject("post", "questions/documents/upload", formData, contentType);
        const docResult = await axios(documentUploadConfig);

        //setPdfUrl(questionRecords == null ? "" : questionRecords);

        resetComponentState();
        setfileurl("");
        showSuccessToast("Patient referral details saved successfully");
      }
      {
        return false;
        //e.preventDefault();
      }
    } catch (err) {
      showFailureToast("failed to post Failure");
      console.error(`failed to fetch Pdf for Type for doctor's error ${err}`);
    }
  };

  // const onSubmit = async (data, e) => {
  //     e.preventDefault();
  //     if (!user?.id) return;
  //     const { id } = user;
  //     const formQuestions = get(data, ["questions", "0"], []);
  //     const formData = new FormData();
  // }
  const uploadFiles = (event) => {
    const files = event.target.files;
    //setValue("file", files);

    console.log("uploaded files are===>>>", files);
    let fileArr = [];
    for (let i = 0; i < files.length; i++) {
      fileArr.push(files[i]);
    }
    if (selectedFiles.length > 0) {
      for (let file of selectedFiles) {
        fileArr.push(file);
      }

      setSelectedFiles(fileArr);
    } else {
      setSelectedFiles(fileArr);
    }
    prepareDataForTable(files);
  };
  const prepareDataForTable = (fileObj) => {
    let fileArr = [];
    for (let i = 0; i < fileObj.length; i++) {
      fileArr.push(fileObj[i]);
    }
    console.log("files array====>>>>>", fileArr);
    const tableHeader = ["Sr", "File Name", "Delete"];
    let tableBody = fileArr.map((f, index) => ({
      sr: index + 1,
      fileName: f.name,
      action: "❌",
      file: f,
    }));
    let prevTabBody = fileAndDesc.slice(1);
    console.log("previous table body===>>> ", prevTabBody);
    console.log("table body", tableBody);

    setFileAndDesc([tableHeader, ...prevTabBody, ...tableBody]);
    console.log("after set state", fileAndDesc);
  };
  const onRemove = (fileName) => {
    console.log("on remove is called for...", fileName);
    let newFileList = fileAndDesc.filter((f) => f.fileName !== fileName);
    console.log("new file list===>>> ", newFileList);
    setFileAndDesc(newFileList);
    // const asArray = Object.entries(selectedFiles);
    const asArray = [...selectedFiles];

    const filtered = asArray.filter((file) => file["name"] !== fileName);
    // const justStrings = Object.fromEntries(filtered);
    console.log("new file list array===>>>", filtered);
    setSelectedFiles(filtered);
  };
  return (
    <FullLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            New Patient Referral
          </Typography>
          <Card>
            {loader == 1 && <LinearProgress color="primary" />}
            <CardContent>
              <form>
                {/* </form> <form onSubmit={handleSubmit(onSubmit)}> */}
                <Box>
                  <Grid container spacing={4} ml={1} mb={5}>
                    <Grid item xs={5}>
                      <Typography sx={{ mb: 2 }}>Specialists:</Typography>
                      <Controller
                        control={control}
                        name="department"
                        render={({ field: { onChange, value, onBlur } }) => (
                          <Select fullWidth size="small" value={value} onChange={onChange} name="department" isClearable={true}>
                            {departmentData.map((search, key) => (
                              <MenuItem value={search.id}>{`${search?.name}`}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.department?.message}
                    </Grid>
                    <Grid item xs={5} ml={2}>
                      <Typography sx={{ mb: 2 }}>Refer To:</Typography>
                      <Controller
                        control={control}
                        name="referTo"
                        defaultValue={null}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <Select fullWidth size="small" value={value} onChange={onChange} name="referTo" isClearable={true} ref={selectRef}>
                            {doctorData.map((search, key) => (
                              <MenuItem key={key} value={search.id}>{`${search?.name}`}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.referTo?.message}
                    </Grid>
                    <Grid container margin={2}>
                      {pdfUrl ? (
                        <div style={{ width: "95%", margin: "2%" }}>
                          <br />
                          <div style={{ display: "block" }}>
                            <iframe src={pdfUrl} width="100%" height={700}></iframe>
                          </div>
                          <br />
                          {/* <div style={{ width: "30%", display: "inline-block" }}>
                            <input ref={fileRef} type="file" class="form-control" multiple name="upload-file" onChange={saveFile} />
                          </div> */}
                          <Grid item xs={12} md={3}>
                            <label htmlFor="contained-button-file">
                              <Input id="contained-button-file" multiple type="file" onChange={uploadFiles} />
                              <Button variant="contained" component="span">
                                Upload Files
                              </Button>
                            </label>
                          </Grid>
                          <br />
                          <Grid container alignItems="center" textAlign="center" justifyContent="center">
                            {fileAndDesc.length > 1 && <DocTable2 data={fileAndDesc} handleRemove={onRemove} />}
                          </Grid>
                          <br />
                          <Grid item xs={12} md={8} lg={12}>
                            {/* <div style={{ display: "inline-block" }}> */}
                            {/* <span style={{ display: "inline-block" }}>
                                For Files over 500 megabyte, Please upload to dropbox and supply location link here:
                              </span> */}
                            {/* <input
                                type="text"
                                class="form-control"
                                name="fileurl"
                                value={fileurl}
                                onChange={(e) => setfileurl(e.target.value)}
                                style={{ width: "65%", vertical: "super" }}
                              /> */}
                            <InputLabel>For Files over 500 megabyte, Please upload to dropbox and supply location link here:</InputLabel>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Dropbox Link"
                              variant="outlined"
                              name="fileurl"
                              value={fileurl}
                              onChange={(e) => setfileurl(e.target.value)}
                            />
                            {/* </div> */}
                          </Grid>
                          <div style={{ width: "20%", display: "block", "margin-top": "2%" }}>
                            <Button variant="contained" fullWidth sx={{ textTransform: "capitalize", p: 1 }} onClick={(event) => submitFile(event)}>
                              SUBMIT REFERRAL
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </FullLayout>
  );
};

export default NewPatient;
