/** @format */

import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const BirthDatePicker = ({ value, onChange, disabled = false, ...rest }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat="dd/MMM/yyyy"
        value={value}
        disabled={disabled}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} fullWidth size="small" />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default BirthDatePicker;
