import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
// import { makeStyles } from "@mui/material";

// const useStyles = makeStyles({
//   tableRow: {
//     height: 5,
//   },
//   tableCell: {
//     padding: "0px 6px",
//   },
// });

const DocTable = ({ data, handleRemove }) => {
  // let tableHeader = data.slice(0, 1);
  let tableBody = data.slice(1);
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 300,
      }}
    >
      <Table
        sx={{ minWidth: "100%", maxHeight: "100%", overflowY: "auto" }}
        stickyHeader
        aria-label="simple table"
      >
        <TableHead>
          <TableRow className={`scroll-container`}>
            <TableCell>Sr</TableCell>
            <TableCell>File Description</TableCell>
            <TableCell>File Name</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{row.desc}</TableCell>
              <TableCell align="left">{row.fileName}</TableCell>
              <TableCell align="left">
                {" "}
                <Button onClick={() => handleRemove(row.fileName)}>
                  {" "}
                  {row.action}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocTable;
