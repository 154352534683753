/** @format */



import React from "react";

import ReactDOM from "react-dom";

import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./Theme/theme";

import App from "./App";

import { CssBaseline } from "@mui/material";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import PatientReferral from "./Component/Home/Referrals/PatientReferral";

import SignIn from "./Component/Home/SignIn";

import PatientProfile from "./Component/Home/Patients/PatientProfile";

import ManualEntry from "./Component/Home/ManualEntry";

import MyInfo from "./Component/Home/MyInfo";

import Error from "./Component/common/Error";

import Search from "./Component/Home/Patients/SearchPatient";

import Dashboard from "./Component/Home/Dashborad";

import NewPatient from "./Component/Home/Referrals/NewPatient/NewPatientReferral";

import NewReferral from "./Component/Home/Referrals/NewReferral";

import Landing from "./Component/Home/Landing";

import InsuranceAccepted from "./Component/Home/InsuranceAccepted";

import NewReferralSource from "./Component/Home/Referrals/NewReferralSource";

import Forgot from "./Component/Home/Forgot";



import ReferralSearch from "./Component/Home/Referrals/ReferralSearch";

import { ProvideAuth } from "./Component/common/UseAuth";

import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

import { PathProvider } from "./Component/common//PathContext";




ReactDOM.render(

  <>

    <CssBaseline />

    <ThemeProvider theme={theme}>

      <BrowserRouter>

        <ProvideAuth>

          <PathProvider>

            <div id="Wrapper">

              <ToastContainer />

              <Routes>

                <Route path="/" element={<Landing />} />

                <Route path="/login" element={<SignIn />} />

                <Route path="/search-patient" element={<Search />} />

                <Route path="/home" element={<Dashboard />} />

                <Route path="/patient-profile/:id" element={<PatientProfile />} />

                <Route path="/referrals" element={<ReferralSearch />} />

                <Route path="/referral/new-patient" element={<NewPatient />} />

                <Route path="/my-info" element={<MyInfo />} />

                <Route path="/manual-entry" element={<ManualEntry />} />

                <Route

                  path="/referral/existing-patient"

                  element={<PatientReferral />}

                />

                <Route path="/assign-doctor" element={<App />} />

                <Route path="/new-referral" element={<NewReferral />} />

                <Route

                  path="/new-referral-source"

                  element={<NewReferralSource />}

                />

                <Route

                  path="/updateReferralSource"

                  element={<updateReferralSource />}

                />

                <Route path="/forgot" element={<Forgot />} />

                <Route path="/insurance-providers" element={<InsuranceAccepted />} /> {/* New Route for InsuranceAccepted component */}

                <Route path="*" element={<Error />} />

              </Routes>

            </div>

          </PathProvider>

        </ProvideAuth>

      </BrowserRouter>

    </ThemeProvider>

  </>,



  document.getElementById("root")

);

