/** @format */



import React from "react";

import Drawer from "@mui/material/Drawer";



import { Box, IconButton, Toolbar, useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";

import ListItemButton from "@mui/material/ListItemButton";

import ListItemIcon from "@mui/material/ListItemIcon";

import ListItemText from "@mui/material/ListItemText";

import { items, collapse } from "./sidebarData";

import referral from "../assets/icons8-linking-24.png";

import { useAuth } from "../Component/common/UseAuth";

import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";

import ExpandMore from "@mui/icons-material/ExpandMore";

import LogoutIcon from "@mui/icons-material/Logout";

import { Avatar, Typography } from "@mui/material";

import { useLocation } from "react-router-dom"; //added

import { usePath } from "../Component/common//PathContext"



const SideBar = ({ drawerWidth, mobileOpen, handleDrawerToggle }) => {

  const [open, setOpen] = React.useState(false);

  /*
  const [image, setImage] = React.useState(

    require("../assets/" +

      JSON.parse(localStorage.getItem("app:userInfo"))?.logo)

  );
  */

  const userLogoPath = JSON.parse(localStorage.getItem("app:userInfo"))?.logo;
  const logoPath = userLogoPath ? require("../assets/" + userLogoPath) : null;

  const location = useLocation();
  const { storePath, navigateToStoredPath } = usePath();

  if (logoPath == null) {
    console.log(location.pathname);
    storePath(location.pathname);
  }

  const { signOut, user } = useAuth();



  const handleClick = () => {

    setOpen(!open);

  };

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {

    defaultMatches: true,

    noSsr: false,

  });

  //  setImage();

  const logOut = async () => {

    try {

      await signOut();

    } catch (err) {

      console.error("Error Occurred While Logging Out", err);

    }

  };



  const navigate = useNavigate();

  const drawer = (

    <>

      <Box sx={{ paddingBottom: "100px", paddingLeft: "35px" }}>

        <IconButton>

          <img src={logoPath} alt="referral" height={75} width={150} />

        </IconButton>

      </Box>

      <Box

        sx={{

          display: "flex",

          flexDirection: "column",

          background: "#0ad0b2 !important",

        }}

      >

        <Box

          flexGrow={1}

          sx={{

            overflow: "auto",

            "&::WebkitScrollbarTrack": {

              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",

              backgroundColor: "#fec90f",

            },

            "&::-webkit-scrollbar": {

              width: "6px",

              backgroundColor: "#6B7280",

            },

            "&::WebkitScrollbarThumb": {

              backgroundColor: "rgba(255,255,255,.04)",

            },

          }}

        >

          <Box alignItems="center" textAlign="center">

            <Avatar

              sx={{ ml: 1, margin: "auto", width: "100px", height: "100px" }}

              src=""

              alt="Profile image"

            ></Avatar>

            <Typography

              variant="h6"

              component="div"

              sx={{ mb: 3 }}

              style={{ color: "black", paddingTop: "16px" }}

            >

              {user?.name}

            </Typography>

          </Box>

          <List>

            {items.map((item) => {

              return (

                <ListItem

                  sx={{

                    pt: 0,

                    pb: 0,

                    pr: 0,

                    display:

                      (user?.roleId === 1 &&

                        (item.id === 1 ||

                          item.id === 7 ||

                          item.id === 4 ||

                          item.id === 5 ||

                          item.id === 8 ||

                          item.id === 9 || item.id === 13)) ||

                        (user?.roleId === 2 &&

                          (item.id === 5 || item.id === 7 || item.id === 101)) ||

                        (user?.roleId === 3 &&

                          (item.id === 2 ||

                            item.id === 1 ||

                            item.id === 4 ||

                            item.id === 7 ||

                            item.id === 5 ||

                            item.id === 101))

                        ? "none"

                        : "block",

                  }}

                  key={item.id}

                  onClick={() => navigate(`${item.route}`)}

                >

                  <ListItemButton

                    sx={{

                      borderRadius: "8px",

                      color: "white",

                      "&:hover": {

                        backgroundColor: "#FFFFFF",

                        color: "black",

                      },

                    }}

                  >

                    <ListItemIcon sx={{ minWidth: "40px" }}>

                      {item.icon}

                    </ListItemIcon>

                    <ListItemText primary={item.title} />

                  </ListItemButton>

                </ListItem>

              );

            })}

            <ListItem

              sx={{

                pt: 0,

                pb: 0,

                borderRadius: "8px",

                display: user?.roleId !== 0 ? "none" : "block",

              }}

            >

              <ListItemButton

                onClick={handleClick}

                sx={{

                  borderRadius: "8px",

                  "&:hover": {

                    backgroundColor: "#FFFFFF",

                    color: "black",

                  },

                }}

              >

                <ListItemIcon>

                  <img src={referral} alt="referral" />

                </ListItemIcon>

                <ListItemText primary="Referral" />

                {open ? <ExpandLess /> : <ExpandMore />}

              </ListItemButton>

            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>

              <List component="div">

                {collapse.map((col) => {

                  return (

                    <ListItemButton

                      sx={{

                        pl: 6,

                        pt: 0,

                        paddingBottom: 0,

                        "&:hover": {

                          backgroundColor: "#FFFFFF",

                          color: "#000000",

                        },

                      }}

                      key={col.id}

                      onClick={() => navigate(`${col.route}`)}

                    >

                      <Box

                        sx={{

                          display: "flex",

                          alignItems: "center",

                          width: "100%",

                          pl: "4px",

                          pr: "4px",

                          borderRadius: "8px",

                          minHeight: "50px",

                        }}

                      >

                        <ListItemIcon sx={{ width: "18px", height: "18px" }}>

                          {col.icon}

                        </ListItemIcon>

                        <ListItemText

                          sx={{

                            "& .MuiListItemText-primary": {

                              fontSize: ".9rem",

                            },

                          }}

                        >

                          {col.title}

                        </ListItemText>

                      </Box>

                    </ListItemButton>

                  );

                })}

              </List>

            </Collapse>



            <ListItem sx={{ pt: 0, pb: 0 }}>

              <ListItemButton

                sx={{

                  borderRadius: "8px",

                  color: "white",

                  "&:hover": {

                    backgroundColor: "#FFFFFF",

                    color: "#000000",

                  },

                }}

                onClick={logOut}

              >

                <ListItemIcon sx={{ minWidth: "40px" }}>

                  <LogoutIcon />

                </ListItemIcon>

                <ListItemText primary={"Log Out"} />

              </ListItemButton>

            </ListItem>

          </List>

        </Box>

      </Box>

    </>

  );

  if (lgUp) {

    return (

      <Drawer

        anchor="left"

        open={mobileOpen}

        PaperProps={{

          sx: {

            backgroundColor: "#0ad0b2",

            color: "#000000",

            width: drawerWidth,

            boxShadow: "rgb(0 0 0 / 12%) 0px 40px 64px",

            borderTopRightRadius: "24px",

            borderBottomRightRadius: "24px",

          },

        }}

        variant="permanent"

      >

        {drawer}

      </Drawer>

    );

  }

  return (

    <Drawer

      anchor="left"

      onClose={handleDrawerToggle}

      open={mobileOpen}

      PaperProps={{

        sx: {

          // backgroundColor: "neutral.100",

          color: "#FFFFFF",

          width: drawerWidth,

        },

      }}

      sx={{

        zIndex: (theme) => theme.zIndex.appBar + 100,

      }}

      variant="temporary"

    >

      {drawer}

    </Drawer>

  );

};



export default SideBar;

