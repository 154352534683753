export const FileDescription = ["    ", "Letter", "X-Ray", "Perio-Chart", "Image"];

export const MaintenanceScheduleData = ["    ",

    "1 month at our office",

    "6 weeks at our office",

    "2 months at our office",

    "3 months at our office",

    "4 months at our office",

    "6 months at our office",

    "1 month alternating with your office",

    "6 weeks alternating with your office",

    "2 months alternating with your office",

    "3 months alternating with your office",

    "4 months alternating with your office",

    "6 months alternating with your office",

];