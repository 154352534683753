import React, { useState, useEffect } from 'react';
import { FaArrowDown } from 'react-icons/fa'; // You can use any icon you prefer
import './ScrollArrow.css'; // Import the CSS file for styling

const ScrollArrow = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    // Check scroll position
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Scroll to the next section when the arrow is clicked
    const scrollToNextSection = () => {
        const nextSection = document.getElementById('next-section');
        if (nextSection) {
            nextSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={`scroll-arrow ${isScrolled ? 'hidden' : ''}`} onClick={scrollToNextSection}>
            <FaArrowDown />
        </div>
    );
};

export default ScrollArrow;