import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { get } from 'lodash';
import { styled } from '@mui/material/styles'; // Import styled utility

const Input = styled('input')({
    display: 'none',
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    backgroundColor: 'white', // Set the background color explicitly to white
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
    margin: 2,
    '&::WebkitScrollbarTrack': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        backgroundColor: '#fec90f',
    },
    '&::-webkit-scrollbar': {
        width: '4px',
        backgroundColor: 'transparent',
    },
    '&::WebkitScrollbarThumb': {
        backgroundColor: 'rgba(255,255,255,.04)',
    },
};



const FileViewer = ({ fileType, files, downloadDocument, onClose, patientId }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const [imageURLs, setImageURLs] = useState([]);

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={style}>
                <Typography variant="h5" gutterBottom textAlign="center" style={{ marginTop: 20 }}>
                    Attached Documents
                </Typography>
                <List>
                    {files.map((file, index) => (
                        <ListItem key={get(file, 'documentId')}>
                            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center', gap: '10px' }}>
                                <ListItemText primary={get(file, 'fileName')} />
                            </div>
                            <Button variant="contained" onClick={() => downloadDocument(get(file, 'documentId'), get(file, 'fileName'))}>
                                View/Download
                            </Button>
                        </ListItem>
                    ))}
                </List>
                <Button onClick={handleClose} variant="contained" style={{ width: '20%', margin: 'auto' }}>
                    Close
                </Button>
            </div>
        </Modal>
    );
};

export default FileViewer;