import React, { useState } from 'react';
import HeaderLanding from './Landing/HeaderLanding';
import NavbarLanding from './Landing/NavbarLanding';
import SplashLanding from './Landing/SplashLanding'; // Home view
import Solutions from './Landing/Solutions'; // Solutions view
import AboutLanding from './Landing/AboutLanding'; // About view
import PricingLanding from './Landing/PricingLanding'; // Pricing view
import ContactLanding from './Landing/ContactLanding'; // Contact section
import styles from './NavbarLanding.module.css';

function Landing() {
    const [currentPage, setCurrentPage] = useState('home'); // Default to Home

    const renderPage = () => {
        switch (currentPage) {
            case 'home':
                return (
                    <>
                        <SplashLanding />
                        <Solutions />
                    </>
                );
            case 'about':
                return <AboutLanding />;
            case 'pricing':
                return <PricingLanding />;
            default:
                return <SplashLanding />;
        }
    };

    return (
        <div className={styles.landingContainer}>
            <HeaderLanding />
            <NavbarLanding setCurrentPage={setCurrentPage} />
            {renderPage()}
            <ContactLanding id="next-section" setCurrentPage={setCurrentPage} />
        </div>
    );
}

export default Landing;