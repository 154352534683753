/** @format */



import React, { useEffect, useState } from "react";

import FullLayout from "../../Layout/FullLayout";

import { Box } from "@mui/system";

import { getPersonAge } from "../common/ValidationConstants";

import {

  Card,

  CardContent,

  Container,

  Grid,

  Divider,

  TextField,

  Button,

  MenuItem,

  TextareaAutosize,

} from "@mui/material";

import { Typography, Select } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";

import { styled } from "@mui/system";

import BirthDatePicker from "../common/BirthDatePicker";

import { useForm, Controller } from "react-hook-form";

import { useAuth } from "../common/UseAuth";

import { get } from "lodash";

import getConfigObject from "../Util/Config";

import axios from "axios";

import { showSuccessToast, showFailureToast, showInProgressToast } from "../common/Toaster";

import DocTable2 from "../common/DocTable2";

import { file } from "jszip";

const Input = styled("input")({

  display: "none",

});

const FieldTitle = styled("div")({

  marginBottom: 8,

  marginTop: 8,

  fontFamily: "Rubik, san-serif",

});



const defaultValues = {

  patientName: "",

  referrer: "",

  dob: null,

  // HomePhone: "",

  patientMobile: "",

  Email: "",

  department: 6,

  fileURL: "",

  comment: "",

  insuranceProvider: "",

};



const ManualEntry = () => {

  const { user } = useAuth();

  const [departmentData, setDepartmentData] = useState([]);

  const [referrerData, setReferrerData] = useState([]);

  const {

    handleSubmit,

    control,

    reset,

    fileRef,

    watch,

    setValue,

    formState: { errors },

  } = useForm({ defaultValues });

  const [fileAndDesc, setFileAndDesc] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [insuranceData, setinsuranceData] = useState([]);



  function getUniqueListBy(arr, key) {

    return [

      ...new Map(

        arr.map(([k, v], index) => {

          //v[0] = index;



          console.log("key & value==>>", k + index, v);

          return [v[key], [k + index, v]];

        })

      ).values(),

    ];

  }

  const uploadFiles = (event) => {

    const files = event.target.files;

    //setValue("file", files);



    console.log("uploaded files are===>>>", files);

    //const asArray = Object.entries(selectedFiles);

    //console.log("uploaded files Array ===>>>", asArray);

    let fileArr = [];

    for (let i = 0; i < files.length; i++) {

      fileArr.push(files[i]);

    }

    if (selectedFiles.length > 0) {

      // const newFilesAsArray = Object.entries(files);

      // let newFileArr = getUniqueListBy([...asArray, ...newFilesAsArray], "name");

      // console.log("unique file Array ===>>>", newFileArr);



      // const justStrings = Object.fromEntries(newFileArr);

      // console.log("new file list obj===>>>", justStrings);

      //   let list = new DataTransfer();

      //   for (let i = 0; i < selectedFiles.length; i++) {

      //     let file = new File([""], selectedFiles[i].name, selectedFiles[i]);

      //     list.items.add(file);

      //   }

      //   for (let i = 0; i < files.length; i++) {

      //     let file = new File(files.item(i), files[i].name, files[i]);

      //     list.items.add(file);

      //   }



      //   console.log("new file list obj===>>>", list.files);



      //   setSelectedFiles(list.files);



      for (let file of selectedFiles) {

        fileArr.push(file);

      }



      setSelectedFiles(fileArr);

    } else {

      setSelectedFiles(fileArr);

    }

    prepareDataForTable(files);

  };

  const prepareDataForTable = (fileObj) => {

    let fileArr = [];

    for (let i = 0; i < fileObj.length; i++) {

      fileArr.push(fileObj[i]);

    }

    console.log("files array====>>>>>", fileArr);

    const tableHeader = ["Sr", "File Name", "Delete"];

    let tableBody = fileArr.map((f, index) => ({

      sr: index + 1,

      fileName: f.name,

      action: "❌",

      file: f,

    }));

    let prevTabBody = fileAndDesc.slice(1);

    console.log("previous table body===>>> ", prevTabBody);

    console.log("table body", tableBody);



    setFileAndDesc([tableHeader, ...prevTabBody, ...tableBody]);

    //console.log("after set state", fileAndDesc);

  };

  const onRemove = (fileName) => {

    console.log("on remove is called for...", fileName);

    let newFileList = fileAndDesc.filter((f) => f.fileName !== fileName);

    console.log("new file list===>>> ", newFileList);

    setFileAndDesc(newFileList);

    // const asArray = Object.entries(selectedFiles);

    const asArray = [...selectedFiles];



    const filtered = asArray.filter((file) => file["name"] !== fileName);

    // const justStrings = Object.fromEntries(filtered);

    console.log("new file list array===>>>", filtered);

    setSelectedFiles(filtered);

  };

  const resetComponentState = () => {

    console.log("In resetComponentState");

    reset(defaultValues);

    setFileAndDesc([]);

    setSelectedFiles([]);

  };

  const submitFile = async (data) => {

    console.log("on submit ===>>> ", data);

    let filesize = 0;



    const formData = new FormData();



    if (selectedFiles?.length > 0) {

      for (let file of selectedFiles) {

        if (file.size > 5374640) {

          showFailureToast("Please upload a file smaller than 5 MB");

          filesize = 1;

          data.preventDefault();

          return;

        }

        console.log("sending ===>>> ", file);

        formData.append("refDocs", file);

      }

    }

    try {

      if (filesize == 0) {

        formData.append(

          "referralId",

          JSON.parse(localStorage.getItem("app:userInfo")).id

        );

        formData.append(

          "referrerId",

          data.referrer ? data.referrer.ID : user.id

        );

        formData.append("fileURL", data.fileURL);

        formData.append("comment", data.comment);



        formData.append("doctorName", data.department);

        formData.append("patientName", data.patientName);

        formData.append("patientDOB", data.dob);

        formData.append("patientEmail", data.Email);



        formData.append("patientMobile", data.patientMobile);

        formData.append(

          "hospitalId",

          JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

        );

        formData.append("practitionerId", data.department);

        formData.append("PatientHomePhone", "");

        formData.append("insuranceProvider", data.insuranceProvider);


        console.log("USER", user?.id);

        let no_match = await searchPatient(data);

        if (!no_match) {
          showFailureToast("Patient already exists");
          return false;
        }

        for (let pair of formData.entries()) {

          console.log(pair[0] + ", " + pair[1]);

        }

        const contentType = "multipart/form-data";

        const documentUploadConfig = getConfigObject(

          "post",

          "questions/manuals",

          formData,

          contentType

        );

        console.log(

          "documentUploadConfig Object ===>>> ",

          documentUploadConfig

        );

        // Show in-progress message
        showInProgressToast("Update in progress...");

        const docResult = await axios(documentUploadConfig);

        console.log("doc uploaded result ===>>> ", docResult);

        resetComponentState();

        showSuccessToast("Patient referral details saved successfully");

      }

      {

        return false;

        //e.preventDefault();

      }

    } catch (err) {

      showFailureToast("failed to post Failure");

      console.error(`failed to fetch Pdf for Type for doctor's error ${err}`);

    }

  };



  const selectChange = (event) => {

    console.log(event.target.value);

    setValue("department", event.target.value);

  };

  const selectChangeReferrer = (newValue) => {

    console.log("referrer========>>>>>>>>>", newValue);

    setValue("referrer", newValue.ID);

  };

  const selectChangeProvider = (newValue) => {

    console.log("Provider========>>>>>>>>>", newValue);

    setValue("insuranceProvider", newValue.ID);

  };

  const departmentValue = watch("department");


  const fetchDoctorData = async () => {

    try {

      const doctorConfig = getConfigObject(

        "get",

        "doctors/" + JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

      );

      const doctorsResult = await axios(doctorConfig);

      const departmentRecords = get(

        doctorsResult,

        ["data", "data", "0", "0", "depatmentDoctor"],

        []

      );

      const deparments = JSON.parse(departmentRecords);

      console.log("Doctor's data===>>> ", deparments);

      setDepartmentData(deparments);

      if (deparments?.length >= 1) {

        setValue("department", deparments[0]?.ID);

      }

    } catch (err) {

      showFailureToast("failed to fetch doctors record");

      console.error("failed to fetch doctors record", err);

    }

  };

  const fetchReferrerData = async () => {

    try {

      const doctorConfig = getConfigObject(

        "get",

        "doctors/referral/" +

        JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

      );

      const doctorsResult = await axios(doctorConfig);

      const departmentRecords = get(

        doctorsResult,

        ["data", "data", "0", "0", "depatmentDoctor"],

        []

      );

      const deparments = JSON.parse(departmentRecords);

      console.log("Referrer data==>>> ", deparments);

      console.log("logged in user data==>>> ", user);



      setReferrerData(deparments);

    } catch (err) {

      showFailureToast("failed to fetch referrer record");

      console.error("failed to fetch referrer record", err);

    }

  };

  const searchPatient = async (data) => {
    try {

      let dataObj = {

        searchTerm: data.patientName,

        searchBy: "firstName",

        hospitalId: JSON.parse(localStorage.getItem("app:userInfo")).hospitalId,

        userId: data.referrer.ID,

      };

      const patientSearchConfig = getConfigObject("post", "patients/searchForDuplicate", dataObj);

      const patientSearchResult = await axios(patientSearchConfig);

      const possibleDuplicates = patientSearchResult.data.data[0][0].possibleDuplicates;

      // console.log("possible duplicates", possibleDuplicates);

      if (possibleDuplicates) {
        const dobString = data.dob.toISOString();
        const dobStringWithoutMilliseconds = dobString.slice(0, -14);

        // Flag to indicate if match is found
        let match_found = false;

        // Extracting the JSON-like substring
        const parsedDuplicates = JSON.parse(possibleDuplicates);

        // Iterate through the parsedDuplicates array
        for (const entry of parsedDuplicates) {
          const patientData = entry.p[0];
          // Check if firstName and dateOfBirth match data.patientName and data.dob
          const dateOfBirthWithoutTime = patientData.dateOfBirth.substring(0, 10);

          if (patientData.firstName === data.patientName && dateOfBirthWithoutTime === dobStringWithoutMilliseconds) {
            // If match found, add to duplicates array
            match_found = true;
            break; // Exit the loop since a match is found
          }
        }

        if (match_found) {
          return false;
        }
      }
      return true; // No duplicate found

    } catch (err) {

      showFailureToast("failed to fetch patient record");

      console.error("failed to fetch patient record", err);

    }
  }

  const fetchInsuranceData = async () => {

    try {

      const hospitalId = JSON.parse(localStorage.getItem("app:userInfo")).hospitalId;

      const insuranceConfig = getConfigObject("get", `user/getInsurance/${hospitalId}`);

      const insuranceResult = await axios(insuranceConfig);

      const insuranceRecords = get(insuranceResult, ["data", "data", "0"], {});

      const insuranceData = insuranceRecords.map((item, index) => ({
        label: item.provider,
        value: index // You can use a unique identifier here if needed, like item.providerId
      }));

      setinsuranceData(insuranceData);

      console.log(insuranceData)

    } catch (err) {

      showFailureToast("failed to fetch insurance record");

      console.error("failed to fetch insurance record", err);

    }

  };

  useEffect(() => {

    const loadInitialData = async () => {

      await Promise.all([fetchDoctorData(), fetchReferrerData(), fetchInsuranceData()]);

    };

    loadInitialData();

  }, []);

  useEffect(() => { }, [departmentValue]);

  return (

    <FullLayout>

      <Box

        component="main"

        sx={{

          flexGrow: 1,

        }}

      >

        <Container maxWidth="lg">

          <Typography sx={{ mb: 3 }} variant="h4">

            Add New Referral

          </Typography>

          <Card sx={{ boxShadow: "rgb(0 0 0 / 28%) 0px 23px 45px" }}>

            <CardContent>

              <form>

                <Box

                  sx={{

                    display: "flex",

                    alignItems: "center",

                    p: "15px 30px",

                  }}

                >

                  <Typography variant="h6">Enter Details</Typography>

                </Box>

                <Divider />

                <Box sx={{ p: "15px 30px" }}>

                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={4}>

                      <Typography sx={{ mb: 2 }}>Referral Source:</Typography>

                      {/* <Controller

                        control={control}

                        name="referrer"

                        render={({ field: { onChange, value } }) => (

                          <Select fullWidth name="referrer" size="small" value={value} onChange={(e) => selectChangeReferrer(e)}>

                            {referrerData.map((search, key) => (

                              <MenuItem value={search.ID}>{`${search?.doctorName}`}</MenuItem>

                            ))}

                          </Select>

                        )}

                      /> */}

                      <Controller

                        control={control}

                        name="referrer"

                        render={({ field: { onChange, value } }) => (

                          <Autocomplete

                            options={referrerData}

                            autoHighlight

                            getOptionLabel={(option) => option.doctorName || ""}

                            renderInput={(params) => <TextField {...params} />}

                            isOptionEqualToValue={(option, value) =>

                              option.value === value.value

                            }

                            value={value}

                            onChange={(e, newValue) => {

                              console.log(

                                "data in autocomplete==>>",

                                newValue ? newValue.ID : "empty"

                              );

                              onChange(newValue);

                            }}

                          />

                        )}

                        onChange={(newValue) =>

                          selectChangeReferrer(newValue.ID)

                        }

                      />

                    </Grid>

                    <Grid item xs={12} sm={4}>

                      <Typography sx={{ mb: 2 }}>

                        Preferred Provider:

                      </Typography>

                      <Controller

                        rules={{ required: true }}

                        control={control}

                        name="department"

                        render={({ field: { onChange, value } }) => (

                          <Select

                            fullWidth

                            name="department"

                            size="small"

                            value={value}

                            onChange={(e) => selectChange(e)}

                          >

                            {departmentData.map((search, key) => (

                              <MenuItem

                                value={search.ID}

                              >{`${search?.doctorName}`}</MenuItem>

                            ))}

                          </Select>

                        )}

                      />

                    </Grid>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Patient Name:</FieldTitle>

                      <Controller

                        rules={{

                          required: true,

                          pattern: {

                            value: /^[a-zA-Z. ]+$/,

                            message: "Enter valid name.",

                          },

                        }}

                        control={control}

                        name="patientName"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            name="patientName"

                            fullWidth

                            value={value}

                            onChange={onChange}

                            type="search"

                          />

                        )}

                      />

                      {errors.patientName &&

                        errors.patientName.type === "required" && (

                          <span style={{ color: "red" }}>

                            This is required field.

                          </span>

                        )}

                      {errors.patientName && (

                        <span style={{ color: "red" }}>

                          {errors.patientName.message}

                        </span>

                      )}

                    </Grid>

                  </Grid>

                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Patient DoB:</FieldTitle>

                      <Controller

                        rules={{
                          required: 'This is a required field.',
                          validate: (value) => {

                            const age = getPersonAge(value);

                            // Check if the age is within the specified range
                            if (age < 1 || age > 110 || age == 'N/A') {
                              return 'Invalid age. Please select a date within the age range of 1 to 110 years.';
                            }

                            console.log(value);
                            console.log(value.getMonth());

                            if (value.getMonth() == 0) {
                              return true;
                            }
                            else if (!value.getMonth()) {
                              return 'Please enter a valid 3-letter month abbreviation.';
                            }

                            return true; // Validation passed
                          },
                        }}

                        control={control}

                        name="dob"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <BirthDatePicker

                            name="dob"

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                      {errors.dob && errors.dob.type === 'required' && (
                        <span style={{ color: 'red' }}>
                          {errors.dob.message}
                        </span>
                      )}
                      {errors.dob && errors.dob.type === 'validate' && (
                        <span style={{ color: 'red' }}>
                          {errors.dob.message}
                        </span>
                      )}

                    </Grid>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Patient Phone:</FieldTitle>

                      <Controller

                        rules={{

                          required: true,

                          pattern: {

                            value: /^[\d ]+$/,

                            message: "Enter valid mobile number.",

                          },

                        }}

                        control={control}

                        name="patientMobile"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            name="patientMobile"

                            fullWidth

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                      {errors.patientMobile &&

                        errors.patientMobile.type === "required" && (

                          <span style={{ color: "red" }}>

                            This is required field.

                          </span>

                        )}

                      {errors.patientMobile && (

                        <span style={{ color: "red" }}>

                          {errors.patientMobile.message}

                        </span>

                      )}

                    </Grid>

                    <Grid item xs={12} sm={4}>

                      <FieldTitle>Insurance Provider:</FieldTitle>

                      <Controller

                        rules={{

                          required: false,
                          /*
                          pattern: {
                            TO-DO: pattern for abbreviations if client chooses.
                          },
                          */

                        }}

                        control={control}

                        name="insuranceProvider"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => (

                          <Autocomplete
                            options={insuranceData}
                            getOptionLabel={(option) => option.label || ""}
                            renderInput={(params) => <TextField {...params} />}
                            isOptionEqualToValue={(option, value) => option.value === value}
                            value={insuranceData.find(option => option.label === value) || null}
                            onChange={(e, newValue) => {
                              console.log(

                                "data in autocomplete==>>",

                                newValue ? newValue.value : "empty"

                              );

                              onChange(newValue ? newValue.label : null); // Send label instead of value
                            }}
                          />

                        )}

                        onChange={(newValue) =>

                          selectChangeProvider(newValue.value)

                        }

                      />

                    </Grid>

                  </Grid>

                  <Grid container spacing={4}>

                    {/* <Grid item xs={12} sm={4}>

                      <FieldTitle>Patient Home Phone:</FieldTitle>

                      <Controller

                        // rules={{ required: true, pattern: { value: /^[\d ]+$/, message: "Enter valid phone number." } }}

                        control={control}

                        name="HomePhone"

                        defaultValue=""

                        render={({ field: { onChange, value } }) => <TextField size="small" fullWidth name="HomePhone" value={value} onChange={onChange} />}

                      />

                      {errors.HomePhone && errors.HomePhone.type === "required" && <span style={{ color: "red" }}>This is required field.</span>}

                      {errors.HomePhone && <span style={{ color: "red" }}>{errors.HomePhone.message}</span>}

                    </Grid> */}

                    <Grid item xs={12} sm={8}>

                      <FieldTitle>

                        For Files over 500 megabyte, Please upload to dropbox, google drive, icloud, etc.

                        and supply location link here 👇:

                      </FieldTitle>

                      <Controller

                        control={control}

                        name="fileURL"

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            size="small"

                            fullWidth

                            name="fileURL"

                            value={value}

                            onChange={onChange}

                          />

                        )}

                      />

                    </Grid>

                  </Grid>

                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={12}>

                      <FieldTitle>Comment:</FieldTitle>

                      <Controller

                        control={control}

                        name="comment"

                        render={({ field: { onChange, value } }) => (

                          <TextField

                            multiline

                            fullWidth

                            name="comment"

                            value={value}

                            onChange={onChange}

                            minRows={3}

                          />

                        )}

                      />

                    </Grid>

                  </Grid>

                  {/* <Grid container spacing={4}>

                    <Grid item xs={12} md={3}>

                      <FieldTitle>File upload:</FieldTitle>

                      <input ref={fileRef} type="file" class="form-control" multiple name="upload-file" onChange={(e) => this.uploadImage(e)} />

                    </Grid>

                  </Grid> */}

                  <br />

                  <Grid container spacing={4}>

                    <Grid item xs={12} md={4}>

                      <label htmlFor="contained-button-file">

                        <Input

                          id="contained-button-file"

                          multiple

                          type="file"

                          //ref={fileRef}

                          // class="form-control"

                          name="upload-file"

                          accept=""

                          onChange={uploadFiles}

                        />

                        <Button variant="contained" component="span">

                          Upload Files

                        </Button>

                      </label>

                    </Grid>

                    <br />

                    <Grid

                      container

                      alignItems="center"

                      textAlign="center"

                      justifyContent="center"

                    >

                      {fileAndDesc.length > 1 && (

                        <DocTable2 data={fileAndDesc} handleRemove={onRemove} />

                      )}

                    </Grid>

                  </Grid>



                  <Box mt={3}>

                    <Button

                      fullWidth

                      variant="contained"

                      type="submit"

                      sx={{ textTransform: "capitalize", p: 1 }}

                      onClick={handleSubmit(submitFile)}

                    >

                      Submit Referral Entry

                    </Button>

                  </Box>

                </Box>

              </form>

            </CardContent>

          </Card>

        </Container>

      </Box>

    </FullLayout>

  );

};



export default ManualEntry;

