/** @format */

import React from "react";
import styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../Component/common/UseAuth";

import { Box } from "@mui/system";
// import PeopleIcon from "@mui/icons-material/People";
// import { NotificationAddTwoTone } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { Grid } from "@mui/material";
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  display: "flex",
}));
const Header = ({ drawerWidth, handleDrawerToggle }) => {
  const { user } = useAuth();
  return (
    <DashboardNavbarRoot
      position="fixed"
      sx={{
        width: {
          lg: `calc(100% - ${drawerWidth}px)`,
        },
        left: {
          lg: drawerWidth,
        },
        backgroundColor: "#F3F4F6 !important"
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            display: {
              xs: "inline-flex",
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
      </Toolbar>

    </DashboardNavbarRoot>
  );
};

export default Header;
