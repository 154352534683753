/** @format */



import * as React from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import { Grid, MenuItem, Select, TextField } from "@mui/material";

import { Link, Tooltip } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { APIEndpoint } from "../../Util/Config";

import axios from "axios";

import download from "../../../assets/download.png";

import toothxrayicon from "../../../assets/toothxrayicon.jpg";

import pdficon from "../../../assets/pdficon.png";

import { useForm, Controller } from "react-hook-form";

import BirthDatePicker from "../../common/BirthDatePicker";

import getConfigObject from "../../Util/Config";

import { showSuccessToast, showFailureToast } from "../../common/Toaster";



const style = {

  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  maxWidth: 500,

  bgcolor: "background.paper",

  boxShadow: 24,

  borderRadius: "8px",

  p: 4,

  display: "flex",

  flexDirection: "column",

  overflow: "auto",



  "&::WebkitScrollbarTrack": {

    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",

    backgroundColor: "#fec90f",

  },

  "&::-webkit-scrollbar": {

    width: "4px",

    backgroundColor: "#922E2E",

  },

  "&::WebkitScrollbarThumb": {

    backgroundColor: "rgba(255,255,255,.04)",

  },

};



const defaultValues = {

  lastName: "",

  email: "",

  officePhone: "",

  loginId: "",

};



export default function UpdateReferralSource(props) {

  const { open, handleClose, handleCloseAndUpdateData, patientInformation } =

    props;



  const {

    handleSubmit,

    control,

    // reset,

    formState: { errors },

    setValue,

  } = useForm({

    defaultValues: defaultValues,

  });



  const updateRecord = () => { };



  const onSubmitHandler = async (data, e) => {

    try {

      e.preventDefault();

      e.stopPropagation();

      console.log(data);

      data.id = patientInformation.id;



      const patientSearchConfig = getConfigObject("post", "user", data);

      const patientSearchResult = await axios(patientSearchConfig);

      handleCloseAndUpdateData(true);

      showSuccessToast("User detail updated successfully");

    } catch (err) {

      showFailureToast("Login id is already exist. Please change it.");

    }

    // handleSubmit(handleSubmitData.saveReferralDetails(data))();

  };



  return (

    <div>

      <Modal

        open={open}

        onClose={handleClose}

        aria-labelledby="modal-modal-title"

        aria-describedby="modal-modal-description"

      >

        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <Box sx={style}>

            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={10}>

                <Typography variant="h4">Referral Information</Typography>

              </Grid>

              <Grid item xs={12} md={2}>

                <Button variant="contained" onClick={handleClose}>

                  Close

                </Button>

              </Grid>

            </Grid>

            <Grid container alignItems="center" sx={{ overflow: "auto" }}>

              <Grid item xs={12}>

                <Grid

                  container

                  justifyContent="space-between"

                  alignItems="center"

                  mb={2}

                  mt={1}

                >

                  <Grid item xs={3} md={3}>

                    <Typography variant="Subtitle">

                      {" "}

                      Referral Source:

                    </Typography>

                  </Grid>

                  <Grid item xs={8} md={8}>

                    <Controller

                      control={control}

                      name="lastname"

                      defaultValue={patientInformation.lastname}

                      render={({ field: { onChange, value, onBlur } }) => (

                        <TextField

                          size="small"

                          fullWidth

                          name="lastname"

                          value={value}

                          onChange={onChange}

                        />

                      )}

                    />

                  </Grid>

                </Grid>

              </Grid>

              <Grid

                container

                justifyContent="space-between"

                alignItems="center"

                mb={2}

                mt={1}

              >

                <Grid item xs={3} md={3}>

                  <Typography variant="Subtitle"> User Name:</Typography>

                </Grid>

                <Grid item xs={8} md={8}>

                  <Controller

                    control={control}

                    name="username"

                    defaultValue={patientInformation.loginId}

                    render={({ field: { onChange, value, onBlur } }) => (

                      <TextField

                        size="small"

                        fullWidth

                        name="username"

                        value={value}

                        onChange={onChange}

                      />

                    )}

                  />

                </Grid>

              </Grid>



              <Grid item xs={12}>

                <Grid

                  container

                  justifyContent="space-between"

                  alignItems="center"

                  mb={2}

                >

                  <Grid item xs={3} md={3}>

                    <Typography variant="Subtitle">Email:</Typography>

                  </Grid>

                  <Grid item xs={8} md={8}>

                    <Controller

                      control={control}

                      name="pemail"

                      defaultValue={patientInformation.email}

                      render={({ field: { onChange, value, onBlur } }) => (

                        <TextField

                          size="small"

                          fullWidth

                          name="pemail"

                          value={value}

                          onChange={onChange}

                        />

                      )}

                    />

                  </Grid>

                </Grid>

              </Grid>



              <Grid item xs={12}>

                <Grid

                  container

                  justifyContent="space-between"

                  alignItems="center"

                  mb={2}

                >

                  <Grid item xs={3} md={3}>

                    <Typography variant="Subtitle">Office Phone:</Typography>

                  </Grid>

                  <Grid item xs={8} md={8}>

                    <Controller

                      control={control}

                      name="phone"

                      defaultValue={patientInformation?.officePhone}

                      render={({ field: { onChange, value, onBlur } }) => (

                        <TextField

                          size="small"

                          fullWidth

                          name="phone"

                          value={value}

                          onChange={onChange}

                        />

                      )}

                    />

                  </Grid>

                </Grid>

              </Grid>



              <Button variant="contained" fullWidth type="submit">

                Submit

              </Button>

            </Grid>

          </Box>

        </form>

      </Modal>

    </div>

  );

}

