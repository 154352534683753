/** @format */

import React, { useEffect, useState } from "react";
import FullLayout from "../../Layout/FullLayout";
import { Box } from "@mui/system";
import {
    Card,
    CardContent,
    Container,
    Grid,
    Divider,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { showSuccessToast, showFailureToast } from "../common/Toaster";
import getConfigObject from "../Util/Config";
import { useAuth } from "../common/UseAuth";
import CreatableSelect from "react-select/creatable";

const FieldTitle = styled("div")({
    marginBottom: 8,
    marginTop: 8,
    fontFamily: "Rubik, san-serif",
});

const defaultValues = {
    insuranceAccepted: "",
};

const InsuranceProviders = () => {
    const { user } = useAuth();
    const hospitalId = JSON.parse(localStorage.getItem("app:userInfo")).hospitalId;
    const [insuranceData, setInsuranceData] = useState([]);
    const [newProvider, setNewProvider] = useState("");

    const fetchInsuranceData = async () => {
        try {
            const insuranceConfig = getConfigObject("get", `user/getInsurance/${hospitalId}`);
            const insuranceResult = await axios(insuranceConfig);
            const insuranceRecords = insuranceResult.data.data[0];
            const insuranceData = insuranceRecords.map((item, index) => ({
                id: index,
                provider: item.provider,
            }));
            setInsuranceData(insuranceData);
        } catch (err) {
            showFailureToast("Failed to fetch insurance providers.");
            console.error("Failed to fetch insurance providers", err);
        }
    };

    useEffect(() => {
        fetchInsuranceData();
    }, []);

    const handleAddProvider = async () => {

        fetchInsuranceData();

        // Check if newProvider is already in insuranceData
        const exists = insuranceData.some(provider => provider.provider === newProvider.trim())

        if (!exists) {
            try {
                console.log(newProvider);
                if (newProvider.trim() !== "") {
                    const insuranceConfig = getConfigObject("post", `user/addInsurance/${hospitalId}`, {
                        provider: newProvider
                    });
                    const insuranceResult = await axios(insuranceConfig);
                    setNewProvider("");
                    fetchInsuranceData();
                    showSuccessToast("Insurance provider added successfully.");
                }
            } catch (err) {
                showFailureToast("Failed to add insurance provider.");
                console.error("Failed to add insurance provider", err);
            }
        }

        else {
            showFailureToast("Provider Already Present.");
        }
    };

    const handleDeleteProvider = async (id) => {
        console.log(insuranceData);

        const providerToDel = insuranceData[id].provider;
        try {
            const insuranceConfig = getConfigObject("post", `user/removeInsurance/${hospitalId}`, {
                provider: providerToDel
            });
            const insuranceResult = await axios(insuranceConfig);
            fetchInsuranceData();
            showSuccessToast("Insurance provider deleted successfully.");

        } catch (err) {
            showFailureToast("Failed to delete insurance provider.");
            console.error("Failed to delete insurance provider", err);
        }
    };

    return (
        <FullLayout>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Container maxWidth="lg">
                    <Typography sx={{ mb: 3 }} variant="h4">
                        Insurance Providers
                    </Typography>
                    <Card sx={{ boxShadow: "rgb(0 0 0 / 28%) 0px 23px 30px" }}>
                        <CardContent>
                            <Box sx={{ p: "15px 30px" }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                </TableHead>
                                                <TableBody>
                                                    {insuranceData.map((provider) => (
                                                        <TableRow key={provider.id}>
                                                            <TableCell>{provider.provider}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => handleDeleteProvider(provider.id)}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ borderLeft: "1px solid #ccc", pl: 5, paddingTop: 2, paddingBottom: 2 }}>
                                        <FieldTitle>Add New Provider:</FieldTitle>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={newProvider}
                                            onChange={(e) => setNewProvider(e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            sx={{ mt: 2 }}
                                            onClick={handleAddProvider}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </FullLayout>
    );
};

export default InsuranceProviders;