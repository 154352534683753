/* eslint-disable import/prefer-default-export */

import React from 'react';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';



const ToastComponent = ({ title, msg }) => (

    <div style={{ display: "flex", flexDirection: "column" }}>

        <div style={{ fontSize: 'larger' }}>{title}</div>

        <div style={{ fontSize: 'small' }}>{msg}</div>

    </div>

);



const toasterDefaultOptions = {

    position: toast.POSITION.TOP_RIGHT,

    hideProgressBar: true,

    closeOnClick: false,

    pauseOnHover: false,

    pauseOnFocusLoss: true,

    draggable: false,

    progress: undefined,

};



export const showSuccessToast = (msg, title, id = null) => toast.success(<ToastComponent title={title || 'Success!'} msg={msg} />, {

    ...toasterDefaultOptions,

    toastId: id,

    autoClose: 5000,

});



export const showFailureToast = (msg, title, id = null) => toast.error(<ToastComponent title={title || 'Failure!'} msg={msg} />, {

    ...toasterDefaultOptions,

    toastId: id,

    autoClose: false,

});



export const showInProgressToast = (msg, title, id = null) => toast.info(<ToastComponent title={title || 'Update in Progress'} msg={msg} />, {

    ...toasterDefaultOptions,

    toastId: id,

    autoClose: 5000,

});



export const dismissToast = (id) => toast.dismiss(id);

