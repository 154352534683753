/** @format */



import { Container, Typography, Box, Grid, Select, TextField, Button, MenuItem, Card, CardContent } from "@mui/material";

import FullLayout from "../../../Layout/FullLayout";

import { getPersonAge } from "../../common/ValidationConstants";

import moment from "moment";

import useLocalStorage from "use-local-storage";

import axios from "axios";

import React, { useState, useEffect } from "react";

import Appointment from "./Appointment";

import { Controller, useForm } from "react-hook-form";

import getConfigObject from "../../Util/Config";

import { get, isEmpty, sortBy, map } from "lodash";

import { useAuth } from "../../common/UseAuth";

import LinearProgress from "@mui/material/LinearProgress";



const errMsg = "Please Enter name to search";

const defaultValue = {

  searchBy: "firstName",

  searchTerm: "",

};



const searchOptions = [{ value: "firstName", display: "Patient Name" }];



function onlyUnique(value, index, self) {

  return self.indexOf(value) === index;

}



const Search = () => {

  const [data, setData] = useState([]);

  const { user } = useAuth();

  const [recordfound, setrecordfound] = useState("0");

  const [loader, setloader] = useState("0");

  const [searchString, setSearchString] = useLocalStorage("searchString", "");



  useEffect(async () => {

    if (searchString !== "") {

      let dataObj = {

        userId: user?.id,

        userType: user?.roleId,

        searchTerm: searchString,

        searchBy: "firstName",

        hospitalId: JSON.parse(localStorage.getItem("app:userInfo")).hospitalId,

      };

      try {

        const patientSearchConfig = getConfigObject("post", "patients/searchByName", dataObj);

        const patientSearchResult = await axios(patientSearchConfig);

        const appointmentsRedcords = get(patientSearchResult, ["data", "data", "0", "0", "appointments"], []);

        const appointments = JSON.parse(appointmentsRedcords);

        console.log("search result", patientSearchResult);
        console.log("search appointments r", appointments);

        if (appointments == null) {

          setData([]);

          setloader(0);

          setrecordfound("-1");

        } else {

          setrecordfound("0");

        }

        if (!isEmpty(appointments)) {

          let mappedData = appointments.map((patient) => {

            let patientDetails = get(patient, ["p", "0"], []);

            let appointmentHistory = get(patientDetails, ["rd"], []);

            let referralDate = get(patient, ["date"]);


            appointmentHistory.forEach((element) => {

              element.commentDate = element.commentDate ? moment(element.commentDate).format("DD/MMM/yyyy") : moment(referralDate).format("DD/MMM/yyyy");

            });

            sortBy(appointmentHistory, "commentDate");

            let appointmentHistoryDates = map(appointmentHistory, "commentDate").filter(onlyUnique);

            return {

              firstName: patientDetails?.firstName,

              lastName: patientDetails?.lastName,

              patientId: patient?.patientId,

              appointmentHistory: appointmentHistoryDates,

              lastLetter: appointmentHistory[0].p1[0].letterdate ? moment(appointmentHistory[0].p1[0].letterdate).format("DD/MMM/yyyy") : "--/--/----",

            };

          });

          setloader(0);

          setData(mappedData);

          console.log(data);

          reset(defaultValue);

          setValue("searchTerm", searchString);

        } else {

          setloader(0);

        }

      } catch (err) {

        console.error("Failed to search patients", err);

      }

    }

  }, []);



  const {

    handleSubmit,

    control,

    reset,

    formState: { errors, isDirty },

    setValue,

  } = useForm({

    mode: "onSubmit",

    defaultValues: defaultValue,

  });



  const selectChange = (event) => {

    console.log(event.target.value);

    setValue("searchBy", "firstName");

  };

  const onSubmit = async (data, e) => {

    e.preventDefault();

    setloader(1);

    data["userId"] = user?.id; /**userId has to come from logged in user Id */

    data["hospitalId"] = JSON.parse(localStorage.getItem("app:userInfo")).hospitalId;

    data["userType"] = user.roleId;

    console.log("Data ====>>>>> ", data, user);

    setSearchString(data.searchTerm);

    //return;

    if (isDirty) {

      try {

        const patientSearchConfig = getConfigObject("post", "patients/searchByName", data);

        const patientSearchResult = await axios(patientSearchConfig);



        const appointmentsRedcords = get(patientSearchResult, ["data", "data", "0", "0", "appointments"], []);



        const appointments = JSON.parse(appointmentsRedcords);

        console.log("appt", appointments);

        if (appointments == null) {

          setData([]);

          setloader(0);

          setrecordfound("-1");

        } else {

          setrecordfound("0");

        }

        if (!isEmpty(appointments)) {

          let mappedData = appointments.map((patient) => {

            let patientDetails = get(patient, ["p", "0"], []);

            let appointmentHistory = get(patientDetails, ["rd"], []);

            let referralDate = get(patient, ["date"]);

            appointmentHistory.forEach((element) => {

              element.commentDate = element.commentDate ? moment(element.commentDate).format("DD/MMM/yyyy") : moment(referralDate).format("DD/MMM/yyyy");

            });

            sortBy(appointmentHistory, "commentDate");

            let appointmentHistoryDates = map(appointmentHistory, "commentDate").filter(onlyUnique);

            return {

              firstName: patientDetails?.firstName,

              lastName: patientDetails?.lastName,

              patientId: patient?.patientId,

              appointmentHistory: appointmentHistoryDates,

              lastLetter: appointmentHistory[0].p1[0].letterdate ? moment(appointmentHistory[0].p1[0].letterdate).format("DD/MMM/yyyy") : "--/--/----",

            };

          });

          setloader(0);

          setData(mappedData);

          console.log(data);

          reset(defaultValue);

        } else {

          setloader(0);

        }

      } catch (err) {

        console.error("Failed to search patients", err);

      }

    }

  };



  return (

    <FullLayout>

      <Box

        component="main"

        sx={{

          flexGrow: 1,

        }}

      >

        <Container maxWidth="lg">

          <Typography sx={{ mb: 3 }} variant="h4">

            Treatment History and Messaging

          </Typography>

          <Card sx={{ height: "100%", boxShadow: "rgb(0 0 0 / 11%) 0px 23px 45px" }}>

            {loader == 1 && <LinearProgress color="primary" />}

            <CardContent>

              <Box

                sx={{

                  p: "15px 30px",

                }}

              >

                <form component="form" onSubmit={handleSubmit(onSubmit)}>

                  <Box>

                    <Grid container spacing={3} justifyContent="center" alignItems="center" >

                      <Grid item xs={12}>

                        <Typography variant="h6">Search By Patient Name (any number of characters):</Typography>

                      </Grid>

                      <Grid item xs={12} sm={5}>

                        <Controller

                          control={control}

                          name="searchTerm"

                          defaultValue=""

                          rules={{

                            required: {

                              value: true,

                              message: (

                                <Typography variant="body2" color="error" mt={1}>

                                  {errMsg}

                                </Typography>

                              ),

                            },

                          }}

                          render={({ field: { onChange, value } }) => <TextField size="small" fullWidth value={value} onChange={onChange} type="search" />}

                        />

                        {errors?.searchTerm?.message || ""}

                      </Grid>

                      <Grid item xs={12} sm={5}>

                        <Button variant="contained" type="submit" fullWidth disableRipple disabled={!isDirty}>

                          Search

                        </Button>

                      </Grid>

                    </Grid>

                  </Box>

                </form>

              </Box>

            </CardContent>

          </Card>



          <Box sx={{ mt: 2 }}>

            <Typography>Any number of letters may be used. The more specific the search critria,the shorter the list will be.</Typography>

          </Box>

          <Box sx={{ mt: 2 }}>

            {recordfound == "-1" ? "No Record found" : ""}

            {data.length ? <Appointment patientData={data} /> : ""}

          </Box>

        </Container>

      </Box>

    </FullLayout>

  );

};



export default Search;

