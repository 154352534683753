/** @format */

import React, { useState, useEffect, } from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import BirthDatePicker from "../../common/BirthDatePicker";
import { EMAIL_REGEX, getValueFromMask } from '../../common/ValidationConstants';

const defaultValues = {
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    homePhone: '',
    mobile: '',
    questions: []
};

const questionConfigTypes = {
    text: [],
    textarea: [],
    select: [],
    checkbox: [],
};

const QUESTIONTYPE = {
    TEXT: "text",
    TEXTAREA: "textarea",
    SELECT: "select",
    CHECKBOX: "checkbox",
};

const PatientReferralForm = (props) => {
    const { patient, control, reset, errors, getValues, questions } = props;

    const [hospitalQuestions, sethospitalQuestions] = useState({
        text: [],
        textarea: [],
        select: [],
        checkbox: [],
    });

    const patientValue = () => {
        const patientData = patient;
        if (!patientData) return defaultValues;
        return {
            id: patient?.id || null,
            firstName: patient?.firstName,
            lastName: patient?.lastName,
            dob: patient?.dob,
            email: patient?.email || '',
            homePhone: patient?.homePhone || '',
            mobile: patient?.mobile || '',
            referTo: getValues('referTo') || '',
            department: getValues('department') || '',
            questions: []
        }
    }

    const fetchQuestions = async () => {
        const questionRecords = questions;
        const hospitalQuestion = { ...hospitalQuestions };

        Object.keys(questionConfigTypes).forEach((questionType) => {
            let filteredData = questionRecords?.filter(
                (data) => data?.type === questionType
            );
            if (!isEmpty(filteredData)) hospitalQuestion[questionType] = filteredData;
        });

        sethospitalQuestions(hospitalQuestion);
    };

    useEffect(() => {
        reset(patientValue());
    }, [patient]);

    useEffect(() => {
        fetchQuestions(questions);
    }, [questions]);

    const dynamicQuestions = () =>
        Object.keys(hospitalQuestions).map((question, objIndex) => {
            if (
                !isEmpty(hospitalQuestions[question]) &&
                question === QUESTIONTYPE.TEXT
            ) {
                return hospitalQuestions[question]?.map((type, index) => {
                    return (
                        <Grid container alignItems="center" mb={2}>
                            <Grid xs={3} item>
                                <Typography sx={{ marginBottom: "1rem" }}>
                                    {type?.questionText + (type?.isMandatory ? " *" : "")}
                                </Typography>
                            </Grid>
                            <Grid xs={9} item>
                                <Controller
                                    control={control}
                                    name={`questions.${0}.${type?.questionText}`}
                                    defaultValue={""}
                                    rules={{
                                        required: {
                                            value: type?.isMandatory,
                                            message: `Please Fill the Field ${type?.questionText}`,
                                        },
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <TextField
                                            size="small"
                                            fullWidth
                                            name={`questions.${0}.${type?.questionText}`}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                <span style={{ color: 'red', marginLeft: '270px', fontSize: 'smaller' }}>
                                    {errors?.questions?.[0]?.[type?.questionText]?.message}
                                </span>
                            }
                        </Grid>
                    );
                });
            } else if (
                !isEmpty(hospitalQuestions[question]) &&
                question === QUESTIONTYPE.TEXTAREA
            ) {
                return hospitalQuestions[question]?.map((type, index) => {
                    return (
                        <Grid container alignItems="center" mb={2}>
                            <Grid xs={3} item>
                                <Typography sx={{ marginBottom: "1rem" }}>
                                    {type?.questionText + (type?.isMandatory ? " *" : "")}
                                </Typography>
                            </Grid>
                            <Grid xs={9} item>
                                <Controller
                                    control={control}
                                    name={`questions.${0}.${type?.questionText}`}
                                    defaultValue={""}
                                    rules={{
                                        required: {
                                            value: type?.isMandatory,
                                            message: `Please Fill the Field ${type?.questionText}`,
                                        },
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={3}
                                            name={`questions.${0}.${type?.questionText}`}
                                            placeholder="Minimum 3 rows"
                                            style={{ width: "100%" }}
                                            sx={{ bg: "transparent", backgroundColor: "transparent" }}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                <span style={{ color: 'red', marginLeft: '270px', fontSize: 'smaller' }}>
                                    {errors?.questions?.[0]?.[type?.questionText]?.message}
                                </span>
                            }
                        </Grid>
                    );
                });
            } else if (
                !isEmpty(hospitalQuestions[question]) &&
                question === QUESTIONTYPE.SELECT
            ) {
                const selectTags = hospitalQuestions[question];

                const distinctSelect = [
                    ...new Set(
                        selectTags
                            .filter((data) => data?.type === QUESTIONTYPE.SELECT)
                            .map((obj) => obj.questionText)
                    ),
                ];

                if (!isEmpty(distinctSelect)) {
                    return distinctSelect?.map((distSelect, index) => {
                        const Options = selectTags
                            .filter((data) => data?.questionText === distSelect)
                            .map((data) => ({
                                value: data.optionText,
                            }));

                        return (
                            <Grid container alignItems="center" mb={2}>
                                <Grid xs={3} item>
                                    <Typography sx={{ marginBottom: "1rem" }}>
                                        {distSelect}
                                    </Typography>
                                </Grid>
                                <Grid xs={9} item>
                                    <Controller
                                        control={control}
                                        name={`questions.${0}.${distSelect}`}
                                        defaultValue={""}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: `Please select the Field ${distSelect}`,
                                            },
                                        }}
                                        render={({ field: { onChange, value, onBlur } }) => (
                                            <Select
                                                fullWidth
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                                name={`questions.${0}.${distSelect}`}
                                            >
                                                {Options.map((search, key) => (
                                                    <MenuItem value={search.value}>
                                                        {search.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </Grid>
                                {
                                    <span style={{ color: 'red', marginLeft: '270px', fontSize: 'smaller' }}>
                                        {errors?.questions?.[objIndex]?.[distSelect]?.message}
                                    </span>
                                }
                            </Grid>
                        );
                    });
                } else return null;
            } else if (
                !isEmpty(hospitalQuestions[question]) &&
                question === QUESTIONTYPE.CHECKBOX
            ) {
                const checkBox = hospitalQuestions?.[question];

                if (!checkBox) return null;

                const distinctCheckBoxOption = [
                    ...new Set(
                        checkBox
                            .filter((data) => data?.type === QUESTIONTYPE.CHECKBOX)
                            .map((obj) => obj.questionText)
                    ),
                ];

                if (!isEmpty(distinctCheckBoxOption)) {
                    return distinctCheckBoxOption?.map((distcheck, distindex) => {
                        const checkBoxes = checkBox
                            .filter((data) => data?.questionText === distcheck)
                            .map((data) => ({
                                value: data.optionText,
                            }));
                        return (
                            <Grid container alignItems="center" mb={3}>
                                <Grid xs={3} item>
                                    <Typography sx={{ marginBottom: "1rem" }}>
                                        {distcheck}
                                    </Typography>
                                </Grid>
                                <Grid xs={9} item>
                                    <Grid container>
                                        {
                                            checkBoxes.map((Box, index) => {
                                                return (
                                                    <Grid xs={5} item>
                                                        <Grid container alignItems="center" >

                                                            <Grid xs={2} item>
                                                                <Controller
                                                                    control={control}
                                                                    name={`questions.${0}.${distcheck}.${Box?.value}`}
                                                                    defaultValue={""}
                                                                    render={({ field: { onChange, value, onBlur } }) => (
                                                                        <Checkbox
                                                                            label={Box?.value}
                                                                            checked={value}
                                                                            onChange={onChange}
                                                                            name={`questions.${0}.${distcheck}.${Box?.value}`}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid xs={10} item>
                                                                <Typography sx={{ fontSize: 'smaller' }}>
                                                                    {Box?.value}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>)
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                {<span style={{ color: 'red', marginLeft: '270px' }}>{errors?.errors?.questions?.[0]?.[distcheck]?.message}</span>}

                            </Grid >
                        );
                    });
                } else return null;
            } else return <></>;
        });

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                paddingBottom: "2em"

            }}
        >
            <Divider />
            <Container maxWidth="m" sx={{ mt: 2, mb: 2 }}>
                <Typography sx={{ mb: 3 }} variant="h6">
                    Patient Details
                </Typography>
                <Grid container alignItems="center" spacing={2} mb={2}>
                    <Grid xs={12} sm={3} item>
                        <Typography>First Name:</Typography>
                    </Grid>
                    <Grid xs={12} sm={9} item>
                        <Controller
                            control={control}
                            name="firstName"
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: "Please Enter First Name",
                                },
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="firstName"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} mb={2}>
                    <Grid xs={12} sm={3} item>
                        <Typography>Last Name:</Typography>
                    </Grid>
                    <Grid xs={12} sm={9} item>
                        <Controller
                            control={control}
                            name="lastName"
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: "Please Enter Last Name",
                                },
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="lastName"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} mb={2}>
                    <Grid xs={12} sm={3} item>
                        <Typography>Birth Date:</Typography>
                    </Grid>
                    <Grid xs={12} sm={9} item>
                        <Controller
                            control={control}
                            name="dob"
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <BirthDatePicker name="dob" value={value} onChange={onChange} />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" mb={2}>
                    <Grid xs={3} item>
                        <Typography sx={{ margin: "1rem 0" }}>Phone No:</Typography>
                    </Grid>
                    <Grid xs={9} item>
                        <Controller
                            control={control}
                            name="homePhone"
                            rules={{
                                validate: (val) => (getValueFromMask(val).length === 10) || 'Please enter a valid phone number',
                            }}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="homePhone"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <span style={{ color: 'red', fontSize: 'smaller' }}>{errors?.homePhone?.message}</span>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" mb={2}>
                    <Grid xs={3} item>
                        <Typography sx={{ margin: "1rem 0" }}>Mobile No:</Typography>
                    </Grid>
                    <Grid xs={9} item>
                        <Controller
                            control={control}
                            name="mobile"
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Mobile number is required.',
                                },
                                validate: (val) => (getValueFromMask(val).length === 10) || 'Please enter a valid phone number',
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="mobile"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <span style={{ color: 'red', fontSize: 'smaller' }}>{errors?.mobile?.message}</span>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" mb={2}>
                    <Grid xs={3} item>
                        <Typography sx={{ margin: "1rem 0" }}>Email:</Typography>
                    </Grid>
                    <Grid xs={9} item>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            rules={{
                                value: true,
                                pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email.' },
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    name="email"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <span style={{ color: 'red', fontSize: 'smaller' }}>{errors?.email?.message}</span>
                    </Grid>
                </Grid>
                {dynamicQuestions()}
                <Grid container alignItems="center" mb={2}>
                    <Grid xs={3} item>
                        <Typography sx={{ margin: "1rem 0" }}>Notes:</Typography>
                    </Grid>
                    <Grid xs={9} item>
                        <Controller
                            control={control}
                            name="notes"
                            defaultValue=""
                            rules={{
                                value: true,
                                message: 'Please enter a notes.'
                            }}
                            render={({ field: { onChange, value, onBlur } }) => (
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    name="notes"
                                    placeholder="Minimum 3 rows"
                                    style={{ width: "100%" }}
                                    sx={{ bg: "transparent", backgroundColor: "transparent" }}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <span style={{ color: 'red', fontSize: 'smaller' }}>{errors?.notes?.message}</span>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} mb={2}>
                    <Grid xs={12} sm={3} item></Grid>
                    <Grid xs={12} sm={9} item>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PatientReferralForm;