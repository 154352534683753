

import React, { useState, useEffect } from "react";

import { IconButton, Link } from "@mui/material";

import Button from "@mui/material/Button";

import CssBaseline from "@mui/material/CssBaseline";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

import { Controller, useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import AuthErrorBox from '../common/AuthErrorBox';

import { useAuth } from '../common/UseAuth';

import { usePath } from '../common/PathContext'; // Import the usePath hook


import logo from "../../assets/login-image.png";


const LoginErrorMesssages = {

  INVALID: 'EMAIL OR PASSWORD IS NOT VALID',

  NOACCESS: 'ACCESS DENIED'

}



export default function SignIn() {

  const { signIn } = useAuth();

  const [loginIncorrect, setLoginIncorrect] = useState(null);

  const { navigateToStoredPath } = usePath(); // Use the usePath hook

  const {

    handleSubmit,

    control,

    formState: { errors, isDirty }

  } = useForm();



  const clearLoginIncorrectError = () => {

    setLoginIncorrect(null);

  };



  const submitData = async (formData, e) => {

    e.preventDefault();

    if (!isDirty) return;

    try {

      await signIn(formData);

      if (navigateToStoredPath) {

        // If sign-in is successful, redirect to the stored path
        navigateToStoredPath();

      }

    } catch (err) {

      console.error('sign in', err);

      setLoginIncorrect('INVALID');

    }

  }

  return (

    <>

      <Box>

        <IconButton>

          <img src={logo} alt="referral" height={100} width={200} />

        </IconButton>

      </Box>

      <Container component="main" maxWidth="xs">

        <CssBaseline />



        <Box

          sx={{

            marginTop: 8,

            display: "flex",

            flexDirection: "column",

            alignItems: "center",

          }}

        >



          <Typography

            component="h2"

            variant="h6"

            color="primary"

            fontWeight={700}

          >

            Login

          </Typography>

          <form

            component="form"

            onSubmit={handleSubmit(submitData)}

            noValidate

            sx={{ mt: 1 }}

          >

            <Controller

              control={control}

              name="loginId"

              defaultValue={""}

              rules={{

                required: {

                  value: true,

                  message: `Please provide login id.`

                }

              }}

              render={({ field: { onChange, value, onBlur } }) => (

                <TextField

                  autoComplete="none"

                  margin="normal"

                  required

                  fullWidth

                  placeholder="Login Id"

                  name="loginId"

                  value={value}

                  onChange={onChange}

                />

              )}

            />

            {errors?.loginId?.message}

            <Controller

              control={control}

              name="password"

              defaultValue={""}

              rules={{

                required: {

                  value: true,

                  message: `Please fill the password field.`

                }

              }}

              render={({ field: { onChange, value, onBlur } }) => (

                <TextField

                  autoComplete="none"

                  margin="normal"

                  required

                  fullWidth

                  name="password"

                  placeholder="Password"

                  type="password"

                  id="password"

                  value={value}

                  onChange={onChange}

                />

              )}

            />

            {errors?.password?.message}





            <Button

              type="submit"

              fullWidth

              variant="contained"

              sx={{ mt: 3, mb: 2, textTransform: "capitalize" }}

              onClick={handleSubmit}

            >

              Login

            </Button>

            {loginIncorrect

              && (

                <AuthErrorBox

                  errorTitle={LoginErrorMesssages[loginIncorrect]}

                  errorMessage="Please enter the correct details and try again!"

                  onClear={clearLoginIncorrectError}

                />

              )}

            <Link

              href="/forgot"

              sx={{ textDecoration: "none", color: "inherit" }}

            >

              <Typography>Forgot password</Typography>

            </Link>

          </form>

        </Box>

      </Container>



    </>

  );

}

