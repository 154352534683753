import moment from 'moment';

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getValueFromMask = (val) => val && val.replace(/[^\d]/g, '');

export const formatDate = (date, dateFormat = 'DD/MMM/yyyy') => {
    const momentDate = moment(date);
    return (momentDate.isValid() ? momentDate.format(dateFormat) : null);
};

export const getPersonAge = (birthDayDate, dateFormat = 'DD/MMM/yyyy') => {
    const todayDate = moment(new Date());
    const birthDate = moment(formatDate(birthDayDate));
    const difference = todayDate.diff(birthDate, 'years');
    return difference || 'N/A';
}