/** @format */



import React from "react";

import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";

import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";

import Link from "@mui/material/Link";

import { Typography, Box, Button } from "@mui/material";

import FolderOpenIcon from "@mui/icons-material/FolderOpen";

import { tableCellClasses } from "@mui/material/TableCell";

import AddDetails from "./../Referrals/AddDetails";

import getConfigObject from "../../Util/Config";

import axios from "axios";

import { showSuccessToast, showFailureToast } from '../../common/Toaster';



import { useNavigate } from "react-router-dom";

import { useState } from "react";

const contentType = 'multipart/form-data';

export default function Appointment({ patientData }) {

    const [viewEditModal, setViewEditModal] = useState(false);

    const [patient, setPatient] = useState({});



    const openViewEditReferral = () => setViewEditModal(true);



    const closeViewEditReferral = () => {

        // setPatient({});

        setViewEditModal(false);

    }



    const navigate = useNavigate();

    const savereferralDetails = async (data) => {



        debugger;



        try {

            const appointment = data?.nextAppointment;



            const referralData = {

                maintenanceSchedule: data?.maintenanceSchedule,

                nextAppointment: appointment?.getFullYear() + '-' + (appointment?.getMonth() + 1) + '-' + appointment?.getDate(),

                caseClosed: data?.caseClosed,

                patientId: data?.patientId,

                referralId: data?.referralId,

                comment: data?.comment,

                desc: data?.desc

            };



            const formData = new FormData();

            if (data?.file && data?.file !== null) {

                formData.append("file", data?.file);

                formData.append("fileName", data?.file?.fileName);

            }

            for (const [key, value] of Object.entries(referralData)) {

                formData.append(key, value);

            }



            const msg = `${data?.caseClosed ? 'Referral details are saved and closed successfully.' : 'Successfully added Referral Details.'}`;



            /**config object */



            /**File Upload */

            const fileConfig = getConfigObject("post", `referral/add`, formData, contentType);



            /**Api Call */

            await axios(fileConfig);





            /**fetch patient */



            closeViewEditReferral();

            showSuccessToast(msg);



        } catch (err) {

            showFailureToast('Failed to add referrals details!.');

            console.error('Error Occured While adding referrals Details', err);

        }

    }

    // Function to find the most recent date from an array of date strings
    const findMostRecentDate = (datesArray) => {
        if (!datesArray || datesArray.length === 0) {
            return null;
        }

        // Convert date strings to Date objects and find the most recent date
        const dates = datesArray.map(dateStr => new Date(dateStr));
        const mostRecentDate = new Date(Math.max.apply(null, dates));

        return mostRecentDate;
    };

    return (

        <TableContainer component={Paper}>

            <Table

                sx={{

                    [`& .${tableCellClasses.root}`]: {

                        borderBottom: "none",

                    },

                }}

            >

                <TableHead

                    sx={{

                        backgroundColor: "#4caf50",

                    }}

                >

                    <TableRow>

                        <TableCell align="center" sx={{ color: "white" }}>

                            Detail

                        </TableCell>

                        <TableCell align="center" sx={{ color: "white" }}>

                            Patient

                        </TableCell>

                        <TableCell align="center" sx={{ color: "white" }}>

                            Most Recent Activity

                        </TableCell>

                        <TableCell align="center" sx={{ color: "white" }}>

                            Last Letter Sent On

                        </TableCell>

                        <TableCell align="center" sx={{ color: "white" }}>



                        </TableCell>

                    </TableRow>

                </TableHead>

                <TableBody>

                    {patientData.map((patient, index) => {

                        return (

                            <TableRow>

                                <TableCell align="center">

                                    <Link

                                        component="button"

                                        onClick={() => navigate(`/patient-profile/${patient?.patientId}`)}

                                    >

                                        <FolderOpenIcon />

                                    </Link>

                                </TableCell>



                                <TableCell align="center">

                                    <Link

                                        component="button"

                                        underline="none"

                                        onClick={() => navigate(`/patient-profile/${patient?.patientId}`)}

                                    >

                                        <Typography>{`${patient?.firstName} ${patient?.lastName}`}</Typography>

                                    </Link>

                                </TableCell>



                                <TableCell align="center">

                                    {/* Display the most recent appointment date */}
                                    <Typography>
                                        {findMostRecentDate(patient.appointmentHistory)?.toLocaleDateString()}
                                    </Typography>


                                    {/*
                                    <Box>

                                        {patient?.appointmentHistory?.map(

                                            (appoint, index) => {

                                                return (

                                                    <Box key={index}>

                                                        <List>

                                                            <ListItem sx={{ justifyContent: "center" }}>

                                                                {appoint}

                                                            </ListItem>

                                                        </List>

                                                    </Box>

                                                );

                                            }

                                        )}

                                    </Box>
                                    */}

                                </TableCell>

                                <TableCell align="center">

                                    <Box>

                                        <Typography>{patient?.lastLetter ? patient?.lastLetter : "--/--/----"}</Typography>



                                        {/* {patient?.appointmentHistory?.map(

                                            (appoint, index) => {

                                                return (

                                                    <Box key={index}>

                                                        <List>

                                                            <ListItem sx={{ justifyContent: "center" }}>

                                                                {appoint?.referralDate}



                                                            </ListItem>

                                                        </List>

                                                    </Box>

                                                );

                                            }

                                        )} */}

                                    </Box>

                                </TableCell>

                                <TableCell>

                                    <Box>

                                        {/*viewEditModal && (

                                            <AddDetails

                                                open={viewEditModal}

                                                handleOpen={openViewEditReferral}

                                                handleClose={closeViewEditReferral}

                                                patientInformation={patient}

                                                handleSubmitData={savereferralDetails}

                                            />

                                        )

                                        <Button

                                            onClick={() => {

                                                openViewEditReferral();

                                            }}

                                            variant="contained"

                                            fullWidth

                                            sx={{ textTransform: "capitalize", p: 1 }}

                                        >

                                            Add Details

                                        </Button>

                                        */}

                                    </Box>

                                </TableCell>

                            </TableRow>

                        );

                    })}

                </TableBody>

            </Table>

        </TableContainer>

    );

}

