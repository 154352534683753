/** @format */



import { Box } from "@mui/system";

import {

  Button,

  Card,

  CardContent,

  Container,

  Grid,

  Link,

  Typography,

} from "@mui/material";

import FullLayout from "../../../Layout/FullLayout";

import { isArray } from "lodash";

import { useAuth } from "../../common/UseAuth";

import { React, useState, useMemo } from "react";

import ViewReferral from "./ViewReferral";

import { get } from "lodash";

import { formatDate, getPersonAge } from "../../common/ValidationConstants";

import AddDetails from "./AddDetails";

import moment from "moment";



const ModalType = Object.freeze({

  View: "View",

  Edit: "Edit",

});



const ReferralList = ({ referrals, saveReferralDetails, searchTerm }) => {

  const { user } = useAuth();



  const [patient, setPatient] = useState({});

  const [viewModal, setViewModal] = useState(false);

  const [viewEditModal, setViewEditModal] = useState(false);


  const openReferral = () => setViewModal(true);

  const openViewEditReferral = () => setViewEditModal(true);



  const closeReferral = () => {

    setPatient({});

    setViewModal(false);

  };



  const closeViewEditReferral = () => {

    setPatient({});

    setViewEditModal(false);

  };



  const setReferralDetails = (patient) => {

    const patientFile = patient?.patient?.r?.map((item) => item.d);

    const patientInformation = {

      patientId: patient?.patient?.patientId,

      firstName: patient?.patient?.pname,

      lastName: patient?.patient?.lname,

      referralDate: getReferralDate(patient),

      referredName: patient?.doctorName,

      reffererName: patient?.reffererName,

      doctorName: patient?.doctorName,

      dob: patient?.patient?.dob,

      mobile: patient?.patient?.mobile,

      email: patient?.patient?.email,

      notes: patient.refferalNotes,

      insuranceProvider: patient.insuranceProvider,

      // files: get(patient, ["patient", "r", "0", "q", "0", "qt", "0", "qp", "0", "d"], []), // old code

      files: isArray(patientFile) ? patientFile[0] : [],

      mappedQuestionAnswers: get(patient, ["patient", "r"], [])?.map(

        (questions) => {

          return {

            answers: get(questions, "answer", ""),

            question: get(questions, ["q", "0", "questionText"], ""),

            questionType: get(questions, ["q", "0", "qt", "0", "type"], ""),

          };

        }

      ),

    };

    console.log(patientInformation);

    setPatient(patientInformation);

  };



  const getAge = (date) => {

    return getPersonAge(date);

  };



  const setEditReferral = (referral) => {

    const patientInformation = {

      patientName: `${referral?.patient?.pname} ${referral?.patient?.lname}`,

      referralDate: referral?.referralDate,

      reffererName: referral?.reffererName,

      referredName: referral?.doctorName,

      dob: referral?.patient?.dob,

      referralId: referral?.referralId,

      age: getAge(referral?.patient?.dob),

      email: referral?.patient?.email,

      mobile: referral?.patient?.mobile,

      patientId: referral?.patient?.patientId,

      comment: "",

      treatmentDate: null,

      treatmentStatus: "",

      desc: "",

      file: null,

      maintenanceSchedule: "",

      nextAppointment: null,

      caseClosed: false,

      insuranceProvider: referral?.patient?.insuranceProvider

    };

    console.log(patientInformation);

    setPatient(patientInformation);

  };



  const viewReferralModal = (patientId, modalType) => {

    const patient = referrals.referrals?.find(

      (referral) => referral?.patient?.patientId === patientId

    );

    if (!patient) return;



    if (modalType === ModalType.View) setReferralDetails(patient);



    if (modalType === ModalType.Edit) setEditReferral(patient);

  };



  const getReferralDate = (referral) => {

    const date = get(referral, ["referralDate"], "");

    if (!date) return date;

    return formatDate(date);

  };


  const filterReferralsByPatientName = (referrals) => {
    console.log(searchTerm, "is the search term");

    // Ensure searchTerm is a string and trim it
    const trimmedSearchString = (searchTerm).trim().toLowerCase();
    console.log(trimmedSearchString, "is the trimmed search term");

    // If the trimmed search string is not empty
    if (trimmedSearchString) {
      // Filter the referrals array
      const filteredReferrals = (referrals || []).filter(referral => {
        // Check if 'pa' exists and is an object
        if (referral.patient && typeof referral.patient === 'object') {
          // Check if the pname matches the search term
          const pname = referral.patient.pname;
          console.log(pname.toLowerCase().includes(trimmedSearchString));
          return pname.toLowerCase().includes(trimmedSearchString);
        }
        return false; // Return false if 'pa' is not an object
      });

      console.log('Filtered referrals:', filteredReferrals);
      return filteredReferrals;
    } else {
      console.log('No search string. Returning all referrals.', referrals);
      return referrals; // Return unfiltered referrals if searchTerm is empty
    }
  };

  const filteredReferrals = filterReferralsByPatientName(referrals.referrals);


  console.log(filteredReferrals);
  console.log(referrals.referrals);

  return (

    // <FullLayout>

    <Box

      component="main"

      sx={{

        flexGrow: 1,

      }}

    >

      {user?.roleId !== 11 && (

        <div>

          <Grid container spacing={2}>

            <Grid item sm={12} xs={12}>

              <Card

                sx={{

                  height: "100%",

                  boxShadow: "rgb(0 0 0 / 28%) 0px 23px 45px",

                }}

              >

                <CardContent>

                  <Box sx={{ mt: 0 }}>

                    <Grid container spacing={2} alignItems="center">

                      <Grid item container xs={12}>

                        <Grid item xs={12} md={2}>

                          <Typography variant={"h6"}>Patient Name</Typography>

                        </Grid>

                        <Grid item xs={12} md={2}>

                          <Typography variant={"h6"}>Referral Date</Typography>

                        </Grid>

                        <Grid item xs={12} md={2}>

                          <Typography variant={"h6"}>Date of Birth</Typography>

                        </Grid>

                        <Grid item xs={12} md={2}>

                          <Typography variant={"h6"}>

                            {user?.roleId !== 1 ? "Referred By" : "Referred To"}

                          </Typography>

                        </Grid>

                        <Grid item xs={12} md={2}>

                          <Typography> </Typography>

                        </Grid>

                        <Grid item xs={12} md={2}>

                          <Typography> </Typography>

                        </Grid>

                      </Grid>

                      {filteredReferrals?.length > 0 &&

                        filteredReferrals?.map((referral, index) => (

                          <Grid item xs={12}>

                            <Grid

                              container

                              spacing={1}

                              alignItems="center"

                              style={{

                                backgroundColor:

                                  referral?.isReferralDetailAdded === "1"

                                    ? "#f1c40f"

                                    : "",

                                paddingBottom: "8px",

                                borderRadius: "6px",

                                paddingRight: "10px",

                              }}

                            >

                              <Grid item xs={12} md={2}>

                                <Link

                                  sx={{

                                    textDecoration: "none",

                                    color: "inherit",

                                  }}

                                >

                                  <Typography>{`${referral?.patient?.pname} ${referral?.patient?.lname}`}</Typography>

                                </Link>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>

                                  {getReferralDate(referral)}

                                </Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>

                                  {moment(referral?.patient?.dob).format(

                                    "DD/MMM/yyyy"

                                  )}

                                </Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                {user?.roleId !== 1 ? (

                                  <Typography>

                                    {" "}

                                    {referral?.reffererName}

                                  </Typography>

                                ) : (

                                  <Typography>

                                    {referral?.referredName}

                                  </Typography>

                                )}

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Button

                                  variant="contained"

                                  fullWidth

                                  sx={{ textTransform: "capitalize", p: 1 }}

                                  onClick={() => {

                                    viewReferralModal(

                                      referral?.patient?.patientId,

                                      ModalType.View

                                    );

                                    openReferral();

                                  }}

                                >

                                  View Referral

                                </Button>

                              </Grid>

                              {user?.roleId !== 1 ? (

                                <Grid item xs={12} md={2}>

                                  <Button

                                    onClick={() => {

                                      viewReferralModal(

                                        referral?.patient?.patientId,

                                        ModalType.Edit

                                      );

                                      openViewEditReferral();

                                    }}

                                    variant="contained"

                                    fullWidth

                                    sx={{ textTransform: "capitalize", p: 1 }}

                                  >

                                    Add Details

                                  </Button>

                                </Grid>

                              ) : (

                                ""

                              )}

                            </Grid>

                          </Grid>

                        ))}

                    </Grid>

                  </Box>

                </CardContent>

              </Card>

            </Grid>

          </Grid>



          {viewModal && (

            <ViewReferral

              open={viewModal}

              handleOpen={openReferral}

              handleClose={closeReferral}

              patientInformation={patient}

            />

          )}

          {viewEditModal && (

            <AddDetails

              open={viewEditModal}

              handleOpen={openViewEditReferral}

              handleClose={closeViewEditReferral}

              patientInformation={patient}

              handleSubmitData={saveReferralDetails}

            />

          )}

        </div>

      )}

    </Box>

    // </FullLayout>

  );

};



export default ReferralList;

