/** @format */

import React from "react";
import { styled } from "@mui/system";

import Header from "./Header";
import SideBar from "./SideBar";

import { Box, IconButton, Toolbar } from "@mui/material";
const ChildWraper = styled("Box")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  padding: 3,
  marginTop: "3",
  marginBottom: "3",
}));
const MainWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: "64",
  backgroundColor: "#F9FAFC",
  height: "100vh",
  marginTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 210,
  },
}));
const FullLayout = ({ children }) => {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>
      <MainWrapper>
        <ChildWraper>
          <Toolbar />
          {children}
        </ChildWraper>
      </MainWrapper>
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      >

      </Header>
      <SideBar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
    </>
  );
};

export default FullLayout;