// PathContext.js
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';

const PathContext = createContext();

export const PathProvider = ({ children }) => {
    const [storedPath, setStoredPath] = useState("");
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const storePath = (path) => {
        setStoredPath(path);
    };

    const navigateToStoredPath = () => {
        if (storedPath) {
            navigate(storedPath);
            setStoredPath(""); // Clear stored path after navigating
        }
    };

    return (
        <PathContext.Provider value={{ storePath, navigateToStoredPath }}>
            {children}
        </PathContext.Provider>
    );
};

export const usePath = () => {
    const context = useContext(PathContext);
    if (!context) {
        throw new Error("usePath must be used within a PathProvider");
    }
    return context;
};