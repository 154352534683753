
import React, { useState } from "react";
import { IconButton, Link } from "@mui/material";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthErrorBox from '../common/AuthErrorBox';
import { useAuth } from '../common/UseAuth';
import logo from "../../assets/logo.png";
import getConfigObject from '../Util/Config';
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { showSuccessToast, showFailureToast } from '../common/Toaster';
import { ArrowBack } from "@mui/icons-material";


export default function Forgot() {
  const { signIn } = useAuth();
  const [loginIncorrect, setLoginIncorrect] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty }
  } = useForm();

  const clearLoginIncorrectError = () => {
    setLoginIncorrect(null);
  };

  const submitData = async (formData, e) => {
    e.preventDefault();
    // if (!isDirty) return;
    try {
      const referralConfig = getConfigObject('post', `user/forgotPassword/${formData.loginId}`, formData);
      const referralUser = await axios(referralConfig);
      showSuccessToast("Password reset successfully, Please check your email to login.");
    } catch (err) {
      showFailureToast("The login ID does not exist.");
    }
  }
  return (
    <>
      <Box>
        <IconButton>
          <img src={logo} alt="referral" height={100} width={200} />
        </IconButton>
      </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <Typography
            component="h2"
            variant="h6"
            color="primary"
            fontWeight={700}
          >
            Forgot Password
          </Typography>
          <form
            component="form"
            onSubmit={handleSubmit(submitData)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              control={control}
              name="loginId"
              defaultValue={""}
              rules={{
                required: {
                  value: true,
                  message: `Please provide login id.`
                }
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <TextField
                  autoComplete="none"
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Login Id"
                  name="loginId"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "capitalize" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>

            <div style={{marginTop: "-20px",textAlign: "end"}}>
            <div style={{display:"inline-block"}}><p><ArrowBack style={{width: "1.4em!important",textAlign: "center",verticalAlign: "bottom",padding:"3px"}}></ArrowBack></p></div>
              <div style={{display:"inline-block"}}> <Link
                href="/signin"
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography> Back to Sign In</Typography>
              </Link></div>

            </div>
          </form>
        </Box>
      </Container>
    </>
  );
}
