/** @format */



import info from "../assets/icons8-information-24.png";

import doctor from "../assets/icons8-stethoscope-24.png";

import referrals from "../assets/referrals.jpg";

import providers from "../assets/icons8-medical-doctor-24.png";

import existingPatient from "../assets/icons8-nurse-call-24.png";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import ViewStreamIcon from "@mui/icons-material/ViewStream";

import searchPatient from "../assets/icons8-search-24.png";

import newPatient from "../assets/icons8-pill-24.png";

import { Dashboard } from "@mui/icons-material";

export const items = [

  {

    id: 1,

    route: "/home",

    icon: <Dashboard />,

    title: "New Referrals",

  },

  {

    id: 101,

    route: "/new-referral",

    icon: <img src={doctor} alt=" Referral Sources" />,

    title: "New Referral",

  },

  {

    id: 2,

    route: "/search-patient",

    icon: <img src={searchPatient} alt="Search Patient" />,

    title: "Treatment History and Messaging",

  },



  {

    id: 3,

    route: "/my-info",

    icon: <img src={info} alt="my information" />,

    title: "My Profile",

  },

  {

    id: 4,

    route: "/referrals",

    icon: <img src={referrals} alt="My Referrals" style={{ height: "26px" }} />,

    title: "View Referrals/ Update Treatments",

  },

  {

    id: 7,

    route: "/referrals",

    icon: <img src={referrals} alt="My Referrals" style={{ height: "26px" }} />,

    title: "View Past Referrals",

  },



  {

    id: 8,

    route: "/manual-entry",

    icon: (

      <img src={referrals} alt="Add New Referral" style={{ height: "26px" }} />

    ),

    title: "Add New Referral",

  },

  {

    id: 5,

    route: "/assign-doctor",

    icon: <img src={doctor} alt="Assign doctor" />,

    title: "Assign Doctor",

  },

  {

    id: 9,

    route: "/new-referral-source",

    icon: <img src={doctor} alt=" Referral Sources" />,

    title: " Referral Sources",

  },

  {

    id: 13,

    route: "/insurance-providers",

    icon: <img src={providers} alt="my information" />,

    title: " Accepted Providers",

  },

];



export const collapse = [

  {

    id: 6,

    route: "/referral/new-patient",

    icon: <img src={newPatient} alt="patient" />,

    title: "New Patient",

  },

  // {

  //   id: 7,

  //   route: "/referral/existing-patient",

  //   icon: <img src={existingPatient} alt="existing Patient" />,

  //   title: "Existing Patient",

  // },

];

