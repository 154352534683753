import React from 'react';
import styles from '../PricingLanding.module.css'; // Import the CSS file

function PricingLanding() {
    return (
        <div className={styles.pricingContainer}>
            <section id="pricing" className={styles.pricingSection}>
                <h2 className={styles.pricingHeading}>Pricing</h2>
                <p className={styles.pricingParagraph}>
                    Initial fee of $450 includes all setup and support plus a 3-month subscription with
                    the first month free. Regular monthly fee only $225. Includes 24/7 telephone support.
                    No contracts or other obligations. Complementary for all of your referring offices.
                </p>
            </section>
        </div>
    );
}

export default PricingLanding;