/** @format */

import { Card, CardContent, Container } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import FullLayout from "../../../Layout/FullLayout";

import {
  Typography, Grid, Box, Select, MenuItem
} from "@mui/material";

import getConfigObject from '../../Util/Config';
import { get } from 'lodash';
import axios from 'axios';
import PatientReferralForm from './PatientReferralForm';
import { useAuth } from "../../common/UseAuth";
import { showSuccessToast, showFailureToast } from '../../common/Toaster';


const defaultValues = {
  firstName: '',
  lastName: '',
  dob: '',
  department: null,
  referTo: null,
  questions: [],
  id: null,
  email: '',
  homePhone: '',
  mobile: '',
};

const PatientReferral = () => {

  const [patientData, setPatientData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const { user } = useAuth();

  const [departmentData, setDepartmentData] = useState([]);

  const [selectedPatient, setSelectedPatient] = useState({});
  const [initialHospitalQuestion, setInitialHospitalQuestion] = useState([]);
  const referralForm = useForm();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty }
  } = referralForm;

  const departmentValue = watch('department');
  const referToValue = watch('referTo');
  const patientId = watch('id');

  const fetchDoctorData = async () => {

    try {

      if (!user?.hospitalId) return;
      const { hospitalId } = user;
      const doctorConfig = getConfigObject('post', 'deparments/hospital', { hospitalId });
      const doctorsResult = await axios(doctorConfig);
      const departmentRecords = get(doctorsResult, ["data", "data", "0", "0", "depatmentDoctor"], []);
      const deparments = JSON.parse(departmentRecords);
      setDepartmentData(deparments);
      setInitialHospitalQuestion([]);
      setValue('referTo', null);

    } catch (err) {

      showFailureToast('Failed to fetch doctors record!');
      console.error('failed to fetch doctors record', err);

    }

  }

  const fetchPatientData = async () => {
    try {
      if (!user?.id) return;
      const { id } = user;
      const patientConfig = getConfigObject('get', `patients/referrar/${id}`);
      const patientResult = await axios(patientConfig);
      const patientRecords = get(patientResult, ["data", "data", "0"], []);

      const patientData = patientRecords.map((patient) => {
        return {
          id: patient?.id,
          firstName: patient?.firstName,
          lastName: patient?.lastName,
          dob: patient?.dateOfBirth,
          email: patient?.email,
          homePhone: patient?.homePhone,
          mobile: patient?.mobile,
        }
      });

      setPatientData(patientData);

    } catch (err) {
      showFailureToast('Failed to fetch patients record!');
      console.error('failed to fetch patients record', err);
    }

  }

  const resetComponentState = () => {
    reset(defaultValues);
    setSelectedPatient({});
    setInitialHospitalQuestion([]);
    setDoctorData([]);
  }


  const fetchQuestions = async () => {

    if (!user?.hospitalId) return;
    const { hospitalId } = user;
    const departmentId = departmentValue;
    const doctorId = referToValue;
    setInitialHospitalQuestion([]);
    try {

      const hospitalQuestionConfig = getConfigObject('post', 'questions/department/question', { hospitalId, departmentId, doctorId });
      const questionResult = await axios(hospitalQuestionConfig);
      const questionRecords = get(questionResult, ["data", "data", "0"], []);
      setInitialHospitalQuestion(questionRecords);

    } catch (err) {
      showFailureToast('Failed to fetch Question Type!.');
      console.error(`Failed to fetch Question Type for doctor's error ${err}`)
    }

  }

  const fetchDoctors = async () => {
    const doctors = get(departmentData?.find((department) => department?.id === departmentValue), "doc", []);
    setDoctorData(doctors);
    setInitialHospitalQuestion([]);
    setValue('referTo', null);
  }


  const selectPatient = () => {
    const patient = patientData?.find((patient) =>
      patient?.id === patientId
    );
    if (patient?.id) setSelectedPatient({ ...patient });
  }

  useEffect(() => {
    const loadInitialData = async () => {
      await Promise.all([fetchPatientData(), fetchDoctorData()]);
    };
    loadInitialData();
  }, []);

  useEffect(() => {
    if (departmentValue) {
      fetchDoctors();
    }
  }, [departmentValue]);

  useEffect(() => {
    if (!isNaN(referToValue) && referToValue) {
      fetchQuestions();
    }
  }, [referToValue]);

  useEffect(() => {
    if (patientId) {
      selectPatient();
    }
  }, [patientId]);

  const onSubmit = async (data, e) => {
    debugger;
    e.preventDefault();
    if (!user?.id) return;
    const { id } = user;
    const formQuestions = get(data, ["questions", "0"], []);

    const mappedQuestions = Object.keys(formQuestions).map((answer) => {

      const id = initialHospitalQuestion?.find((question) =>
        question?.questionText === answer
      )?.id;

      let questionAnswers = formQuestions[answer];

      if (typeof formQuestions[answer] === 'object')
        questionAnswers = Object.keys(formQuestions[answer]).filter((val) =>
          formQuestions[answer][val]
        ).toString();

      return {
        id,
        "value": questionAnswers,
      }
    });

    const formobj = {
      ...data,
      questions: mappedQuestions,
      userId: id,
    }

    try {

      const hospitalQuestionAnswerConfig = getConfigObject('post', 'patients/referral/create', formobj);
      await axios(hospitalQuestionAnswerConfig);
      resetComponentState();
      showSuccessToast('Patient referral saved successfully.');

    } catch (err) {

      showFailureToast('Failed to save patient referral!.');
      console.error(`Error Occurred While Saving Question Answer `, err);

    }

  }

  return (
    <FullLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Existing Patient Referral
          </Typography>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{ display: "flex", alignItems: "center", p: "15px 30px" }}
                >
                  <Grid container spacing={4} ml={1} mb={5}>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ mb: 2 }}>Specialists:</Typography>
                      <Controller
                        control={control}
                        name="department"
                        render={({ field: { onChange, value, onBlur } }) => (
                          <Select
                            fullWidth
                            size="small"
                            value={value}
                            onChange={onChange}
                            name="department"
                            isClearable={true}
                          >

                            {departmentData.map((search, key) => (
                              <MenuItem
                                value={search.id}
                              >{`${search?.name}`}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.department?.message}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography sx={{ mb: 2 }}>Refer To:</Typography>
                      <Controller
                        control={control}
                        name="referTo"
                        defaultValue={null}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <Select
                            fullWidth
                            size="small"
                            value={value}
                            onChange={onChange}
                            name="referTo"
                            isClearable={true}
                          >

                            {doctorData.map((search, key) => (
                              <MenuItem
                                value={search.id}
                              >{`${search?.name}`}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.referTo?.message}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography mb={2}>Existing Patient:</Typography>
                      <Controller
                        control={control}
                        name="id"
                        defaultValue={""}
                        rules={{
                          required: {
                            value: true,
                            message: `Please select the patient field.`
                          }
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <Select
                            fullWidth
                            size="small"
                            value={value}
                            onChange={onChange}
                            name="id"
                            isClearable={true}
                          >
                            {patientData.map((search, key) => (
                              <MenuItem
                                value={search.id}
                              >{`${search?.firstName} ${search?.lastName}`}</MenuItem>
                            ))}
                          </Select>
                        )} />
                      {<span style={{ color: 'red', marginLeft: '270px' }}>{errors?.id?.message}</span>}
                    </Grid>
                  </Grid>
                </Box>

                <Grid container gap={2}>
                  {selectedPatient?.id && initialHospitalQuestion?.length ? (
                    <PatientReferralForm
                      control={control}
                      reset={reset}
                      errors={errors}
                      patient={selectedPatient}
                      getValues={getValues}
                      questions={initialHospitalQuestion}
                    />
                  ) : null}
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </FullLayout>

  );
};

export default PatientReferral;
