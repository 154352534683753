/** @format */



import { Box } from "@mui/system";

import moment from "moment";

import {

  Button,

  Card,

  CardContent,

  Container,

  Grid,

  Link,

  Typography,

} from "@mui/material";



import LinearProgress from "@mui/material/LinearProgress";

import FullLayout from "../../../Layout/FullLayout";

import { useAuth } from "../../common/UseAuth";

import { React, useState, useEffect } from "react";

import getConfigObject from "../../Util/Config";

import ViewReferral from "./ViewReferral";

import UpdateReferralSource from "./UpdateReferralSource";

import AddReferralSource from "./AddReferralSource";

import { get, isArray } from "lodash";



import axios from "axios";

import { formatDate, getPersonAge } from "../../common/ValidationConstants";

import AddDetails from "./AddDetails";

import { showSuccessToast, showFailureToast } from "../../common/Toaster";



const ModalType = Object.freeze({

  View: "View",

  Edit: "Edit",

  Add: "Add",

});



const contentType = "multipart/form-data";



const NewReferralSource = () => {

  const { user } = useAuth();



  const [referralData, setreferralData] = useState([]);

  const [patient, setPatient] = useState({});

  const [viewModal, setViewModal] = useState(false);

  const [AddModal, setAddModal] = useState(false);

  const [viewEditModal, setViewEditModal] = useState(false);



  const openReferral = () => setViewModal(true);

  const openAddReferral = () => setAddModal(true);

  const openViewEditReferral = () => setViewEditModal(true);



  const handleCloseAndUpdateData = async (update) => {

    const referralConfig = getConfigObject(

      "get",

      "user/hospitals/" +

      JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

    );

    const referralResult = await axios(referralConfig);



    const referralRecords = get(referralResult, ["data", "data", "0"], []);

    const doctorReferrals = referralRecords?.map((patients) => {

      return {

        id: patients?.id,

        lastname: patients?.lastName,

        email: patients?.email,

        loginId: patients?.loginId,

        officePhone: patients?.officePhone,

      };

    });



    setreferralData(doctorReferrals);

    setPatient({});

    setViewModal(false);

    setAddModal(false);

  };



  const closeReferral = () => {

    setPatient({});

    setViewModal(false);

    setAddModal(false);

  };



  const handleCloseAndUpdateData1 = async () => {

    const referralConfig = getConfigObject(

      "get",

      "user/hospitals/" +

      JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

    );

    const referralResult = await axios(referralConfig);



    const referralRecords = get(referralResult, ["data", "data", "0"], []);

    const doctorReferrals = referralRecords?.map((patients) => {

      return {

        id: patients?.id,

        lastname: patients?.lastName,

        email: patients?.email,

        loginId: patients?.loginId,

        officePhone: patients?.officePhone,

      };

    });



    setreferralData(doctorReferrals);

    setPatient({});

    setViewModal(false);

    setAddModal(false);

  };



  const closeAddReferral = () => {

    setPatient({});

    setViewModal(false);

    setAddModal(false);

  };



  const closeViewEditReferral = async () => {

    const referralConfig = getConfigObject(

      "get",

      "user/hospitals/" +

      JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

    );

    const referralResult = await axios(referralConfig);



    const referralRecords = get(referralResult, ["data", "data", "0"], []);

    const doctorReferrals = referralRecords?.map((patients) => {

      return {

        id: patients?.id,

        lastname: patients?.lastName,

        email: patients?.email,

        loginId: patients?.loginId,

        officePhone: patients?.officePhone,

      };

    });



    setreferralData(doctorReferrals);

    setPatient({});

    setViewEditModal(false);

  };



  const ReferredNewPatients = async () => {

    const newref = getConfigObject("post", `user/addNewRef`, "", contentType);

    const newrefresult = await axios(newref);

  };

  const fetchReferredPatients = async () => {

    try {

      //  if (!user?.id) return;



      //  const { id } = user;

      const referralConfig = getConfigObject(

        "get",

        "user/hospitals/" +

        JSON.parse(localStorage.getItem("app:userInfo")).hospitalId

      );

      const referralResult = await axios(referralConfig);



      const referralRecords = get(referralResult, ["data", "data", "0"], []);

      const doctorReferrals = referralRecords?.map((patients) => {

        return {

          id: patients?.id,

          lastname: patients?.lastName,

          email: patients?.email,

          loginId: patients?.loginId,

          officePhone: patients?.officePhone,

        };

      });



      setreferralData(doctorReferrals);

    } catch (err) {

      showFailureToast("Failed to fetch Referrals.");

      console.error("Error Occurred while fetching", err);

    }

  };



  const saveReferralAcknowledgement = async (referralId) => {

    try {

      const fileConfig = getConfigObject(

        "post",

        `referral/acknowledgment/${referralId}`

      );

      const msg = "Acknowledgement saved!";

      await axios(fileConfig);

      await fetchReferredPatients();



      showSuccessToast(msg);

    } catch (err) {

      showFailureToast("Failed to save referral acknowledgement!");

      console.error("Error Occured While adding referrals Details", err);

    }

  };



  const ResetPassword = async (id) => {

    const result = window.confirm(

      `You are reseting password for "${id.lastname}" . This will send email to ${id.email} . Are you sure?`

    );

    if (result) {

      const newref = getConfigObject(

        "post",

        `user/resetPassword/${id.id}`,

        JSON.stringify(id)

      );

      const newrefresult = await axios(newref);

      showSuccessToast("Password Reset successfully. Please check your email.");

    } else {

    }

  };



  const setReferralDetails = (patient) => {

    // const patientFile = patient?.patient?.d?.map(item => item);

    const patientInformation = {

      id: patient?.id,

      lastname: patient?.lastname,

      email: patient?.email,

      loginId: patient?.loginId,

      officePhone: patient?.officePhone,

    };

    setPatient(patientInformation);

  };



  const getAge = (date) => {

    if (!date) return "N/A";

    return getPersonAge(date);

  };



  const setEditReferral = (referral) => {

    const patientInformation = {

      patientName: `${referral?.patient?.pname} ${referral?.patient?.lname}`,

      referralDate: referral?.referralDate,

      reffererName: referral?.reffererName,

      referredName: referral?.referredName,

      dob: referral?.patient?.dob,

      referralId: referral?.referralId,

      age: getAge(referral?.patient?.dob),

      mobile: referral?.patient?.mobile,

      patientId: referral?.patient?.patientId,

      comment: "",

      desc: "",

      file: null,

      maintenanceSchedule: "",

      nextAppointment: null,

      caseClosed: false,

    };

    setPatient(patientInformation);

  };



  const viewReferralModal = (patientId, modalType) => {

    const patient = referralData?.find(

      (referral) => referral?.id === patientId

    );



    if (!patient) return;



    if (modalType === ModalType.View) setReferralDetails(patient);



    if (modalType === ModalType.Edit) setEditReferral(patient);

  };



  useEffect(() => {

    fetchReferredPatients();

  }, []);



  const getReferralDate = (referral) => {

    const date = get(referral, ["referralDate"], "");

    if (!date) return date;

    return formatDate(date);

  };

  return (

    <FullLayout>

      <Box

        component="main"

        sx={{

          flexGrow: 1,

        }}

      >

        {user?.roleId !== 1 && (

          <Container maxWidth="lg">

            <Grid container spacing={2}>

              <Grid item sm={10} xs={10}>

                <Typography sx={{ mb: 3 }} variant="h4">

                  Referral Sources

                </Typography>

              </Grid>

              <Grid item sm={2} xs={2}>

                <Button

                  variant="contained"

                  fullWidth

                  sx={{

                    textTransform: "capitalize",

                    p: 1,

                    background: "#0ad0b2",

                  }}

                  onClick={() => {

                    // viewReferralModal(referral?.patient?.patientId, ModalType.View);

                    openAddReferral();

                  }}

                >

                  Add New

                </Button>

              </Grid>

            </Grid>

            <Grid container spacing={2}>

              <Grid item sm={12} xs={12}>

                <Card

                  sx={{

                    height: "100%",

                    boxShadow: "rgb(0 0 0 / 28%) 0px 23px 45px",

                  }}

                >

                  {referralData?.length === 0 && (

                    <LinearProgress color="primary" />

                  )}



                  <CardContent>

                    <Box sx={{ mt: 4 }}>

                      <Grid container spacing={2} alignItems="center">

                        <Grid item container xs={12}>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>

                              Referral Source

                            </Typography>

                          </Grid>

                          <Grid item xs={12} md={3}>

                            <Typography variant={"h6"}>Email</Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>Phone</Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>Username</Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>Action </Typography>

                          </Grid>



                          <Grid item xs={12} md={2}>

                            <Typography> </Typography>

                          </Grid>

                        </Grid>

                        {referralData?.map((referral, index) => (

                          <Grid item xs={12}>

                            <Grid container spacing={1} alignItems="center">

                              <Grid item xs={12} md={2}>

                                <Link

                                  sx={{

                                    textDecoration: "none",

                                    color: "inherit",

                                  }}

                                >

                                  <Typography>{`${referral?.lastname}`}</Typography>

                                </Link>

                              </Grid>

                              <Grid item xs={12} md={3}>

                                <Typography>{`${referral?.email}`}</Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>{referral?.officePhone}</Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>{referral?.loginId}</Typography>

                              </Grid>

                              <Grid item xs={12} md={3}>

                                <Button

                                  variant="contained"

                                  sx={{

                                    textTransform: "capitalize",

                                    p: 1,

                                    background: "#0ad0b2",

                                  }}

                                  onClick={() => {

                                    viewReferralModal(

                                      referral?.id,

                                      ModalType.View

                                    );

                                    openReferral();

                                  }}

                                >

                                  Edit

                                </Button>{" "}

                                &nbsp;&nbsp;&nbsp;

                                <Button

                                  variant="contained"

                                  sx={{

                                    textTransform: "capitalize",

                                    p: 1,

                                    background: "#1d1d9b",

                                  }}

                                  onClick={() => {

                                    ResetPassword(referral, ModalType.View);

                                  }}

                                >

                                  Reset Password

                                </Button>

                              </Grid>

                            </Grid>

                          </Grid>

                        ))}

                      </Grid>

                    </Box>

                  </CardContent>

                </Card>

              </Grid>

            </Grid>



            {/* <updateReferralSource

                open={viewModal}

                handleOpen={openReferral}

                handleClose={closeReferral}

                patientInformation={patient}



              /> */}

            {viewModal && (

              <UpdateReferralSource

                open={viewModal}

                handleOpen={openReferral}

                handleClose={closeReferral}

                handleCloseAndUpdateData={handleCloseAndUpdateData}

                patientInformation={patient}

              />

            )}



            {AddModal && (

              <AddReferralSource

                open={AddModal}

                handleOpen={openAddReferral}

                handleClose={closeAddReferral}

                handleCloseAndUpdateData={handleCloseAndUpdateData1}

                patientInformation={patient}

              />

            )}

          </Container>

        )}

      </Box>

    </FullLayout>

  );

};



export default NewReferralSource;

