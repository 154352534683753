/** @format */



import React, { useEffect, useState } from "react";

import FullLayout from "../../../Layout/FullLayout";

import { saveAs } from "file-saver";

import download from "../../../assets/download.png";

import { Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Select, MenuItem, Grid, TextField, Typography, Link, Tooltip } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useParams } from "react-router-dom";

import getConfigObject, { APIEndpoint } from "../../Util/Config";

import axios from "axios";

import { get } from "lodash";

import TreatmentHistory from "./TreatmentHistory";

import { useAuth } from "../../common/UseAuth";

import moment from 'moment';

import { useNavigate } from "react-router-dom"; // new

import { ProvideAuth } from "../../common/UseAuth"

import { useForm, Controller } from "react-hook-form";

import { FileDescription } from "../../common/FileDescriptionData";

import { styled } from "@mui/material/styles";

import UploadFiles from "../../common/UploadFiles";

import DocTable from "../../common/DocTable";

import { isArray } from "lodash";

import { showSuccessToast, showFailureToast, showInProgressToast } from "../../common/Toaster";


window.addEventListener('unhandledrejection', function (event) {
  console.warn('Unhandled Promise Rejection in:', event.reason);
});


const PatientProfile = () => {

  const [comment, setComment] = useState("");

  const [comments, setPatientComments] = useState([]);

  const [selectedFile, setSelectedFile] = useState();

  const ref = React.useRef();

  const { user, isAuthenticated } = useAuth(); // added isAuthenticated

  const userInfoKey = "app:userInfo";

  const Input = styled("input")({

    display: "none",

  });

  const getUserInfoFromLocalStorage = () => {

    try {

      return JSON.parse(localStorage.getItem(userInfoKey));

    } catch (err) {

      return null;

    }

  };

  const userData = getUserInfoFromLocalStorage();

  const navigate = useNavigate(); // new

  const { id } = useParams();

  const [patient, setPatient] = useState({});

  const [treatments, setTreatments] = useState([]);

  const contentType = "multipart/form-data";

  const [fileAndDesc, setFileAndDesc] = useState([]);

  const defaultValues = {

    comment: comment,

    treatmentDate: new Date(),

    file: null,

    maintenanceSchedule: "",

    files: [],

  };

  const [fileDescValue, SetFileDescValue] = useState("");

  const {

    handleSubmit,

    control,

    reset,

    formState: { errors },

    setValue,

  } = useForm({

    defaultValues: defaultValues,

  });


  const submitText = async () => {

    fetchPatientsHistoryComments();

    setComment("");

  };


  const saveFile = (event) => {

    setSelectedFile(event.target.files[0]);

  };

  const downloadDocument = async (documentId, fileName) => {

    try {

      const url = `patients/documents/${documentId}`;

      const serverUrl = `${APIEndpoint}${url}`;

      const res = await fetch(serverUrl);

      const blob = await res.blob();

      const urlDownload = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");

      link.href = urlDownload;

      link.setAttribute("download", fileName);



      // Append to html link element page

      document.body.appendChild(link);



      // Start download

      link.click();

      // Clean up and remove the link

      link.parentNode.removeChild(link);

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };



  const downloadDocumentZip = async (documentId, fileName) => {

    try {

      var FileSaver = require("file-saver");

      var xhr = new XMLHttpRequest();

      const url = `patients/documents/${documentId}/${fileName}`;

      const serverUrl = `${APIEndpoint}${url}`;

      console.log("Response:");

      xhr.open("Get", serverUrl, true);

      xhr.setRequestHeader("Content-type", "application/json");

      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

      xhr.onreadystatechange = function () {

        if (xhr.readyState == 4 && xhr.status == 200) {

          var blob = new Blob([xhr.response], { type: "octet/stream" });

          FileSaver.saveAs(blob, xhr.responseURL.split("/")[8] + ".zip");

        }

      };

      xhr.responseType = "arraybuffer";

      xhr.send();

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };

  const fetchPatientsTreatmentHistory = async () => {

    try {
      const doctorConfig = getConfigObject("get", `patients/history/${id}`);

      const patientResult = await axios(doctorConfig);

      const patientsTreatmentHistory = get(patientResult, ["data", "data", 0], "[]");

      const firstEntry = patientsTreatmentHistory[0];
      const referralDatePromise = fetchPatientProfile();
      const referralDate = await referralDatePromise;

      if (firstEntry.date === null) {
        const updatedFirstEntry = {
          ...firstEntry, date: referralDate, treatment: "Original referral submitted",
        };
        const updatedPatientsTreatmentHistory = [updatedFirstEntry, ...patientsTreatmentHistory.slice(1)];
        setTreatments(updatedPatientsTreatmentHistory);

      }
      else {
        const newObject = {
          date: referralDate,
          treatment: "Original referral submitted",
          // ... other properties for the new object
        };

        const updatedPatientsTreatmentHistory = [...patientsTreatmentHistory, newObject];
        setTreatments(updatedPatientsTreatmentHistory);

      }
    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };



  const fetchPatientsHistoryComments = async () => {

    try {

      const patientsHistoryCommentsConfig = getConfigObject("get", `patients/comments/${id}`);

      patientsHistoryCommentsConfig.headers["UserId"] = user;

      const patientComments = await axios(patientsHistoryCommentsConfig);

      const patientsHistoryComments = get(patientComments, ["data", "data", 0], "[]");

      setPatientComments(patientsHistoryComments);

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };


  const uploadFiles = (event) => {

    const files = event.target.files;

    setValue("file", files);

    prepareDataForTable(files);

  };

  const prepareDataForTable = (fileObj) => {

    let fileArr = [];

    for (let i = 0; i < fileObj.length; i++) {

      fileArr.push(fileObj[i]);

    }

    const tableHeader = ["Sr", "File Description", "File Name", "Delete"];

    let tableBody = fileArr.map((f, index) => ({

      sr: index + 1,

      desc: fileDescValue,

      fileName: f.name,

      action: "❌",

      file: f,

    }));

    let prevTabBody = fileAndDesc.slice(1);

    console.log("previous table body===>>> ", prevTabBody);

    console.log("table body", tableBody);



    setFileAndDesc([tableHeader, ...prevTabBody, ...tableBody]);

    console.log("after set state", fileAndDesc);

  };

  const formatPhoneNumber = (phoneNumber) => {
    // Ensure phoneNumber is a string
    const phoneString = String(phoneNumber);

    // Extract digits only
    const digits = phoneString.replace(/\D/g, '');

    // Format as (xxx)xxx-xxxx
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };


  const fetchPatientProfile = async () => {

    try {

      const doctorConfig = getConfigObject("get", `patients/${id}`);

      const patientResult = await axios(doctorConfig);

      const patient = get(patientResult, ["data", "data", 0, 0], "{}");

      console.log("patient data ===>>> ", patient);

      //patient.nxtAppt = moment(patient.nxtAppt).format("DD/MMM/yyyy");

      patient.date = moment(patient.date).format("DD/MMM/yyyy");

      setPatient(patient);

      return patient.referralDate;

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };

  const setDescription = (event) => {

    SetFileDescValue(event.target.value);

    console.log(event.target.value);

  };


  const onRemove = (fileName) => {

    console.log("on remove is called for...", fileName);

    let newFileList = fileAndDesc.filter((f) => f.fileName !== fileName);

    setFileAndDesc(newFileList);

  };

  const saveReferralDetails = async (data) => {

    try {

      // Strip apostrophes from the comment
      const strippedComment = comment.replace(/'/g, '');

      const referralData = {

        comment: strippedComment,

        desc: data?.desc,

        fileAndDescription: JSON.stringify(data?.fileAndDesc),

        userId: user?.id,

      };

      const formData = new FormData();

      const filesUploaded = data.fileAndDesc.map((fd) => fd.file);



      if (filesUploaded?.length > 0 && filesUploaded !== null) {

        filesUploaded.map((fileItem) => formData.append("files", fileItem));

      }



      for (const [key, value] of Object.entries(referralData)) {

        formData.append(key, value);

      }

      /**config object */

      /**File Upload */

      const commentConfig = getConfigObject("post", `patients/comments/${id}`, formData, contentType);

      /**Api Call */

      await axios(commentConfig);

      showSuccessToast("Comment Added Successfuly");

      fetchPatientsHistoryComments();

      submitText();
      /**fetch patient */

    } catch (err) {

      showFailureToast("Failed to add Files.");

      console.error("Error Occured While adding referrals Details", err);

    }

  };

  const onSubmitHandler = async (data, e) => {

    e.preventDefault();

    e.stopPropagation();

    if (!comment) {
      showFailureToast("Please add a comment.");
      return;
    }

    else {
      if (isArray(fileAndDesc)) {

        fileAndDesc.shift();

        data.fileAndDesc = fileAndDesc;

      }
      //console.log(data);
      handleSubmit(saveReferralDetails(data));
    }
  }

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!userData) {
          navigate("/");
          return;
        }

        await fetchPatientProfile();

        fetchPatientsTreatmentHistory();
        fetchPatientsHistoryComments();

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (

    <FullLayout>

      <Box component="main" sx={{ flexGrow: 1, }}>

        <Container maxWidth="lg">

          <Typography sx={{ mb: 3 }} variant="h4">

            Patient Profile

          </Typography>

          <Grid container spacing={3}>

            <Grid item xs={12}>

              <Card sx={{ height: "100%" }}>

                <CardContent>

                  <Grid item container spacing={1}>

                    <Grid item xs={3}>

                      <Typography>Name:</Typography>

                    </Grid>

                    <Grid item xs={3} justifyContent="space-between">

                      <Typography>{patient?.name}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Treating Doctor(s):</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{patient?.treatmentDoctor ? patient?.treatmentDoctor : "Not Provided"}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Date Of Birth:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{moment(patient?.dateOfBirth).format("DD/MMM/yyyy")}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Insurance Provider:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{patient?.insuranceProvider ? patient?.insuranceProvider : "Not Provided"}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Phone:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{formatPhoneNumber(patient?.phone)}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Maintenance Schedule:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{patient?.mSchedule ? patient.mSchedule : "Not Provided"}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Referral Date:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{moment.utc(patient?.referralDate).format("DD/MMM/yyyy")}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Next Appointment:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>
                        {patient?.nxtAppt !== null ? (
                          moment(patient.nxtAppt).format("DD/MMM/yyyy") !== 'Invalid date' ? moment(patient.nxtAppt).format("DD/MMM/yyyy") : "Not Provided"
                        ) : (
                          "Not Provided"
                        )}
                      </Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Referring Practice:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{patient?.referringDoctor}</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>Treatment Status:</Typography>

                    </Grid>

                    <Grid item xs={3}>

                      <Typography>{patient?.treatmentStatus ? patient?.treatmentStatus : "Not Provided"}</Typography>

                    </Grid>

                  </Grid>

                </CardContent>

              </Card>

            </Grid>

            <Grid item xs={1} sm={6}></Grid>

          </Grid>

          <Card sx={{ height: "100%" }}>

            <CardHeader title=" Treatment History/General Messages" />

            <Divider />

            <CardContent sx={{ height: "500px", overflow: "auto" }}>

              <TreatmentHistory treatments={treatments} comments={comments} downloadDocument={downloadDocument} downloadDocumentZip={downloadDocumentZip} patient={patient} />

            </CardContent>

          </Card>

          <Card sx={{ height: "100%", mt: "24px" }}>

            <CardContent>

              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <Grid item container spacing={2}>

                  <Grid item xs={12}>

                    <Typography>Send Message:</Typography>

                  </Grid>

                  <Grid item xs={12}>

                    <TextField value={comment} onChange={(e) => setComment(e.target.value)} fullWidth multiline />

                  </Grid>

                  <Grid item xs={12} md={2}>

                    <Typography variant="body2">File Description:</Typography>

                  </Grid>

                  <Grid item xs={12} md={2}>

                    <Controller

                      control={control}

                      name="desc"

                      render={({ field: { onChange, value, onBlur } }) => (

                        <Select

                          fullWidth

                          size="small"

                          value={fileDescValue}

                          onChange={setDescription}

                          name="desc"

                          isClearable={true}

                        >

                          {FileDescription.map((el, key) => (

                            <MenuItem key={key} value={el}>

                              {el}

                            </MenuItem>

                          ))}

                        </Select>

                      )}

                    />

                  </Grid>

                  <Grid item xs={12} md={3}>

                    <Typography variant="body2">File Upload:</Typography>

                  </Grid>

                  <Grid item xs={12} md={3}>

                    <label

                      htmlFor="contained-button-file"

                      hidden={fileDescValue === ""}

                    >

                      <Input

                        id="contained-button-file"

                        multiple

                        type="file"

                        onChange={uploadFiles}

                      />

                      <Button variant="contained" component="span">

                        Upload Files

                      </Button>

                    </label>

                  </Grid>

                  <Grid

                    container

                    spacing={2}

                    alignItems="stretch"

                    textAlign="center"

                    justifyContent="center"

                  >

                    <Grid item xs={12} md={10} marginTop={2}>

                      {fileAndDesc.length > 1 && (

                        <DocTable data={fileAndDesc} handleRemove={onRemove} />

                      )}

                    </Grid>

                  </Grid>

                  <Grid item xs={12}>

                    <Button type="submit" variant="contained" fullWidth>

                      Send Message

                    </Button>

                  </Grid>

                </Grid>

              </form>


            </CardContent>

          </Card>

        </Container>

      </Box>

    </FullLayout >

  );

};



export default PatientProfile;

