/** @format */



import { Box } from "@mui/system";

import moment from "moment";

import {

  Button,

  Card,

  CardContent,

  Container,

  Grid,

  Link,

  Typography,

} from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";

import FullLayout from "../../Layout/FullLayout";

import { useAuth } from "../common/UseAuth";

import { React, useState, useEffect } from "react";

import getConfigObject from "../../Util/Config";

import ViewReferral from "./Referrals/ViewReferral";

import { get, isArray } from "lodash";



import axios from "axios";

import { formatDate, getPersonAge } from "../common/ValidationConstants";

import AddDetails from "./Referrals/AddDetails";

import { showSuccessToast, showFailureToast } from "../common/Toaster";



const ModalType = Object.freeze({

  View: "View",

  Edit: "Edit",

});



const contentType = "multipart/form-data";



const Dashborad = () => {

  const { user } = useAuth();



  const [referralData, setreferralData] = useState([]);

  const [patient, setPatient] = useState({});

  const [viewModal, setViewModal] = useState(false);

  const [viewEditModal, setViewEditModal] = useState(false);



  const openReferral = () => setViewModal(true);

  const openViewEditReferral = () => setViewEditModal(true);



  const closeReferral = () => {

    setPatient({});

    setViewModal(false);

  };



  const closeViewEditReferral = () => {

    setPatient({});

    setViewEditModal(false);

  };



  const fetchReferredPatients = async () => {

    try {

      if (!user?.id) return;



      const { id } = user;

      const userInfoString = localStorage.getItem("app:userInfo");
      const userInfo = JSON.parse(userInfoString)
      const { hospitalId } = userInfo;

      const referralConfig = getConfigObject("get", `referral/${id}/${hospitalId}`);

      const referralResult = await axios(referralConfig);



      const referralRecords = get(

        referralResult,

        ["data", "data", "0", "0", "referralData"],

        []

      );

      const doctorReferrals = JSON.parse(referralRecords)?.map((patients) => {

        return {

          patient: get(patients, ["pa", "0"], []),

          doctorName: get(

            patients,

            ["pa", "0", "d", "0", "pr", "0", "doctorName"],

            ""

          ),

          referralDate: patients?.referralDate,

          referralId: patients?.referralId,

          reffererName: patients?.reffererName,

          referredName: patients?.referredName,

          refferalNotes: patients?.referralNotes,

        };

      });



      setreferralData(doctorReferrals);

    } catch (err) {

      showFailureToast("Failed to fetch Referrals.");

      console.error("Error Occurred while fetching", err);

    }

  };



  const saveReferralAcknowledgement = async (referralId) => {

    try {

      const fileConfig = getConfigObject(

        "post",

        `referral/acknowledgment/${referralId}`

      );

      const msg = "Acknowledgement sent!";

      await axios(fileConfig);

      await fetchReferredPatients();



      showSuccessToast(msg);

    } catch (err) {

      showFailureToast("Failed to save referral acknowledgement!");

      console.error("Error Occured While adding referrals Details", err);

    }

  };



  const setReferralDetails = (patient) => {

    const patientFile = patient?.patient?.d?.map((item) => item);

    const patientInformation = {

      firstName: patient?.patient?.pname,

      lastName: patient?.patient?.lname,

      email: patient?.patient?.email,

      mobile: patient?.patient?.mobile,

      dob: patient?.patient?.dob,

      referralDate: getReferralDate(patient),

      referredName: patient?.referredName,

      reffererName: patient?.reffererName,

      notes: patient.refferalNotes,

      doctorName: patient?.doctorName,

      documentId: patient?.patient?.docPdfUrl,

      fileUrl: patient?.patient?.FileUrl,

      // files: get(patient, ["patient", "r", "0", "q", "0", "qt", "0", "qp", "0", "d"], []),

      files: isArray(patientFile) ? patientFile : [],

      mappedQuestionAnswers: get(patient, ["patient", "r"], [])?.map(

        (questions) => {

          return {

            answers: get(questions, "answer", ""),

            question: get(questions, ["q", "0", "questionText"], ""),

            questionType: get(

              questions,

              ["d", "0", "q", "0", "qt", "0", "type"],

              ""

            ),

          };

        }

      ),

    };

    setPatient(patientInformation);

  };



  const getAge = (date) => {

    if (!date) return "N/A";

    return getPersonAge(date);

  };



  const setEditReferral = (referral) => {

    const patientInformation = {

      patientName: `${referral?.patient?.pname} ${referral?.patient?.lname}`,

      referralDate: referral?.referralDate,

      reffererName: referral?.reffererName,

      referredName: referral?.referredName,

      dob: referral?.patient?.dob,

      referralId: referral?.referralId,

      age: getAge(referral?.patient?.dob),

      mobile: referral?.patient?.mobile,

      patientId: referral?.patient?.patientId,

      comment: "",

      desc: "",

      file: null,

      maintenanceSchedule: "",

      nextAppointment: null,

      caseClosed: false,

    };

    setPatient(patientInformation);

  };



  const viewReferralModal = (patientId, modalType) => {

    const patient = referralData?.find(

      (referral) => referral?.patient?.patientId === patientId

    );



    if (!patient) return;



    if (modalType === ModalType.View) setReferralDetails(patient);



    if (modalType === ModalType.Edit) setEditReferral(patient);

  };



  useEffect(() => {
    if (user) {
      fetchReferredPatients();
    }

  }, [user]);



  const getReferralDate = (referral) => {

    const date = get(referral, ["referralDate"], "");

    if (!date) return date;

    return formatDate(date);

  };

  return (

    <FullLayout>

      <Box

        component="main"

        sx={{

          flexGrow: 1,

        }}

      >

        {user?.roleId !== 1 && (

          <Container maxWidth="lg">

            <Typography sx={{ mb: 3 }} variant="h4">

              New Referrals

            </Typography>

            <Grid container spacing={2}>

              <Grid item sm={12} xs={12}>

                <Card

                  sx={{

                    height: "100%",

                    boxShadow: "rgb(0 0 0 / 28%) 0px 23px 45px",

                  }}

                >

                  {referralData?.length === 0 && (

                    <LinearProgress color="primary" />

                  )}



                  <CardContent>

                    <Typography variant="h6">Assigned To Me</Typography>

                    <Box sx={{ mt: 4 }}>

                      <Grid container spacing={2} alignItems="center">

                        <Grid item container xs={12}>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>Patient Name</Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>

                              Referral Date

                            </Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>

                              Date of Birth

                            </Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography variant={"h6"}>Referred By</Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography> </Typography>

                          </Grid>

                          <Grid item xs={12} md={2}>

                            <Typography> </Typography>

                          </Grid>

                        </Grid>

                        {referralData?.map((referral, index) => (

                          <Grid item xs={12}>

                            <Grid container spacing={1} alignItems="center">

                              <Grid item xs={12} md={2}>

                                <Link

                                  sx={{

                                    textDecoration: "none",

                                    color: "inherit",

                                  }}

                                >

                                  <Typography>{`${referral?.patient?.pname} ${referral?.patient?.lname}`}</Typography>

                                </Link>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>

                                  {getReferralDate(referral)}

                                </Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>

                                  {referral?.patient?.dob

                                    ? moment(referral?.patient?.dob).format(

                                      "DD/MMM/yyyy"

                                    )

                                    : "--/--/----"}

                                </Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Typography>

                                  {referral?.reffererName}

                                </Typography>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Button

                                  variant="contained"

                                  fullWidth

                                  sx={{

                                    textTransform: "capitalize",

                                    p: 1,

                                    background: "#0ad0b2",

                                  }}

                                  onClick={() => {

                                    viewReferralModal(

                                      referral?.patient?.patientId,

                                      ModalType.View

                                    );

                                    openReferral();

                                  }}

                                >

                                  View Referral

                                </Button>

                              </Grid>

                              <Grid item xs={12} md={2}>

                                <Button

                                  onClick={() => {

                                    saveReferralAcknowledgement(

                                      referral?.referralId

                                    );

                                  }}

                                  variant="contained"

                                  fullWidth

                                  sx={{

                                    textTransform: "capitalize",

                                    p: 1,

                                    background: "#0ad0b2",

                                  }}

                                >

                                  Send Acknowledge

                                </Button>

                              </Grid>

                            </Grid>

                          </Grid>

                        ))}

                      </Grid>

                    </Box>

                  </CardContent>

                </Card>

              </Grid>

            </Grid>



            {viewModal && (

              <ViewReferral

                open={viewModal}

                handleOpen={openReferral}

                handleClose={closeReferral}

                patientInformation={patient}

              />

            )}

          </Container>

        )}

      </Box>

    </FullLayout>

  );

};



export default Dashborad;

