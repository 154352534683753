import React from 'react';
import styles from '../AboutLanding.module.css'; // Import the CSS file


function About() {
    return (
        <div className={styles.aboutContainer}>
            <section id="about" className={styles.aboutSection}>
                <h2 className={styles.aboutHeader}>About Us</h2>
                <p className={styles.cta}>
                    The program was developed in conjunction with a team of very experienced specialists,
                    generalists and practice advisors who perceived the lack of a COST EFFECTIVE, simple
                    to use communication portal to enhance mutual patient care and optimize the their time,
                    and that of their teams.
                </p>
            </section>
        </div>
    );
}

export default About;