
const FieldErrorText = (props) => {

    const { errorMessage } = props;

    const styleObj = {
        color: "#dc3545",
        fontSize: 'small'
    }

    if (!errorMessage) {
        return null;
    }

    return (
        <div style={styleObj}>
            {`${errorMessage}  *`}
        </div>
    );
};

export default FieldErrorText;
