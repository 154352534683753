import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from '../NavbarLanding.module.css'; // Import the CSS file

function HeaderLanding() {
    return (
        <header className={styles.header}>
            <div className={styles.contactInfo}>
                <div className={styles.contactItem}>
                    <FontAwesomeIcon icon={faPhone} className={styles.icon} />
                    <span>520.528.6242</span>
                </div>
                <div className={styles.contactItem}>
                    <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
                    <span>info@mypatientstatus.com</span>
                </div>
            </div>
        </header>
    );
}

export default HeaderLanding;

