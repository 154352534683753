

//export const APIEndpoint = 'http://localhost:3000/api/v1/';

export const APIEndpoint = 'https://mypatientstatus.com//api/v1/';



const getConfigObject = (method, url, data, type) => {

    let serverUrl = `${APIEndpoint}${url}`;

    return {

        "method": method || 'post',

        "url": serverUrl,

        "headers": {

            'Content-Type': type || "application/json"

        },

        "data": data || {}

    }

};

export default getConfigObject;