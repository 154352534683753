import React, { useState } from 'react';
import styles from '../ContactLanding.module.css'; // Import the CSS file
import stylesnav from '../NavbarLanding.module.css';
import axios from 'axios'; // Ensure axios is installed
import getConfigObject from "../../Util/Config";

function Contact({ setCurrentPage }) {

    const [formData, setFormData] = useState({
        practiceName: '',
        preferredPhoneContact: '',
        preferredEmail: '',
        teamMemberContact: '',
        message: '',
    });

    const initialFormData = {
        practiceName: '',
        preferredPhoneContact: '',
        preferredEmail: '',
        teamMemberContact: '',
        message: '',
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const clearFormData = () => {
        setFormData(initialFormData);
    };

    const sendContactEmail = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const referralConfig = getConfigObject('post', 'user/send-contact-email', formData);
            const response = await axios(referralConfig);

            if (response.status === 200) {
                alert('Message sent successfully!');
                clearFormData();
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message.');
        }
    };

    const scrollToSection = (event, sectionId, page) => {
        if (page !== "Overview") {
            setCurrentPage(page); // Set current page here
        }

        event.preventDefault();
        const navbarHeight = document.querySelector(`.${styles.navbarr}`).offsetHeight;
        const targetElement = document.querySelector(sectionId);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - navbarHeight,
                behavior: 'smooth'
            });
        }

    };

    return (
        <section id="contact" className={styles.contactSection}>
            <div className={styles.menu}>
                <h3>Menu</h3>
                <ul>
                    <li><a href="#solutions" onClick={(e) => scrollToSection(e, '#solutions', "home")}>Overview</a></li>
                    <li><a href="#about" onClick={(e) => scrollToSection(e, '#about', "about")}>About</a></li>
                    <li><a href="#pricing" onClick={(e) => scrollToSection(e, '#pricing', "pricing")}>Pricing</a></li>
                </ul>
            </div>

            <div className={styles.menu}>
                <h3>Contact</h3>
                <ul>
                    <li>520-528-6242</li>
                    <li>info@mypatientstatus.com</li>
                </ul>
            </div>

            <div className={styles.contactForm}>
                <form >
                    <label>
                        <input type="text" name="practiceName" placeholder="Practice Name" onChange={handleChange} />
                    </label>
                    <label>
                        <input type="email" name="preferredPhoneContact" placeholder="Preferred Phone Contact" onChange={handleChange} />
                    </label>
                    <label>
                        <input type="text" name="preferredEmail" placeholder="Preferred Email" onChange={handleChange} />
                    </label>
                    <label>
                        <input type="text" name="teamMemberContact" placeholder="Team Member Contact (optional)" onChange={handleChange} />
                    </label>
                    <label>
                        <textarea name="message" rows="6" placeholder="Message" onChange={handleChange}></textarea>
                    </label>
                </form>

                <button className={styles.contactButton} type="submit" onClick={sendContactEmail}>Send Message</button>
            </div>
        </section >
    );
}

export default Contact;