import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

// import { makeStyles } from "@mui/material";

// const useStyles = makeStyles({
//   tableRow: {
//     height: 5,
//   },
//   tableCell: {
//     padding: "0px 6px",
//   },
// });
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "auto",
  },
}));
const DocTable2 = ({ data, handleRemove }) => {
  let tableBody = data.slice(1);

  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 200,
      }}
    >
      <Table sx={{ minWidth: 650, height: "auto" }} stickyHeader aria-label="doc table2" size="small">
        <TableHead className={`scroll-container`}>
          <StyledTableRow>
            <TableCell>Sr</TableCell>
            <TableCell>File Name</TableCell>
            <TableCell>Delete</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((row, index) => (
            <StyledTableRow key={index}>
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{row.fileName}</TableCell>
              <TableCell align="left">
                {" "}
                <Button onClick={() => handleRemove(row.fileName)} style={{ maxWidth: "0px", maxHeight: "0px", minWidth: "0px", minHeight: "0px" }}>
                  {" "}
                  {row.action}
                </Button>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocTable2;
