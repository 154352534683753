/** @format */



import {

  Container,

  Typography,

  Box,

  Grid,

  Select,

  TextField,

  Button,

  MenuItem,

  Card,

  CardContent,

} from "@mui/material";

import FullLayout from "../../../Layout/FullLayout";

import getConfigObject from "../../Util/Config";

import axios from "axios";

import { showSuccessToast, showFailureToast } from "../../common/Toaster";

import React, { useState, useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import { get } from "lodash";

import { useAuth } from "../../common/UseAuth";

import ReferralList from "./ReferralList";

import BirthDatePicker from "../../common/BirthDatePicker";

import LinearProgress from "@mui/material/LinearProgress";

import useLocalStorage from "use-local-storage";

const contentType = "multipart/form-data";



const errMsg = "Please Enter name to search";

const defaultValue = {

  startDate: new Date(new Date().setDate(new Date().getDate() - 30)),

  endDate: new Date(),

  frequency: 30,

};



const ReferralSearch = () => {

  const [referrals, setReferrals] = useState([]);

  //const [gettingReferrals, setGettingReferrals] = useState(true);

  const { user } = useAuth();

  const [loader, setloader] = useState("0");

  const [searchString, setSearchString] = useState("");

  const {

    handleSubmit,

    control,

    reset,

    formState: { errors, isDirty },

    getValues,

    setValue,

  } = useForm({

    mode: "onSubmit",

    defaultValues: defaultValue,

  });



  const divStyle = {

    color: "blue",

    "border-left": "2px solid green",

    height: "100px",

    "margin-left": "10px",

    "margin-top": "10px",

  };



  const onSubmit = async (data, e) => {

    e.preventDefault();



    data["userId"] = user?.id; /**userId has to come from logged in user Id */

    // if (isDirty) {

    try {

      fetchReferrals(data);

      //reset(defaultValue);

    } catch (err) {

      console.error("Failed to search patients", err);

    }

    // }

  };



  const saveReferralDetails = async (data) => {

    try {

      const appointment = data?.nextAppointment;

      const treatment = data?.treatmentDate;

      // Strip apostrophes from the comment
      const strippedComment = data?.comment.replace(/'/g, '');

      const referralData = {

        maintenanceSchedule: data?.maintenanceSchedule,

        nextAppointment: appointment

          ? appointment?.getDate() +

          "/" +

          (appointment?.getMonth() + 1) +

          "/" +

          appointment?.getFullYear()

          : "",

        // nextAppointment: appointment ? appointment?.getFullYear() + "-" + (appointment?.getMonth() + 1) + "-" + appointment?.getDate() : "",

        caseClosed: data?.caseClosed,

        patientId: data?.patientId,

        treatmentDate: treatment
          ? `${treatment.getDate()}/${treatment.getMonth() + 1}/${treatment.getFullYear()} ${treatment.getHours()}:${treatment.getMinutes()}:${treatment.getSeconds()}`
          : "",

        // treatmentDate: treatment ? treatment?.getFullYear() + "-" + (treatment?.getMonth() + 1) + "-" + treatment?.getDate() : "",

        treatmentStatus: data?.treatmentStatus,

        referralId: data?.referralId,

        comment: strippedComment,

        insuranceProvider: data?.insuranceProvider,

        desc: data?.desc,

        fileAndDescription: JSON.stringify(data?.fileAndDesc),

      };



      const formData = new FormData();

      const filesUploaded = data.fileAndDesc.map((fd) => fd.file);



      if (filesUploaded?.length > 0 && filesUploaded !== null) {

        filesUploaded.map((fileItem) => formData.append("files", fileItem));

      }



      for (const [key, value] of Object.entries(referralData)) {

        formData.append(key, value);

      }



      const msg = `${data?.caseClosed

        ? "Referral details are saved and closed successfully."

        : "Successfully added Referral Details."

        }`;



      /**config object */

      /**File Upload */

      const fileConfig = getConfigObject(

        "post",

        `referral/add`,

        formData,

        contentType

      );



      /**Api Call */

      await axios(fileConfig);

      showSuccessToast(msg);

      await fetchReferrals();

      /**fetch patient */

    } catch (err) {

      showFailureToast("Failed to add referrals details!.");

      console.error("Error Occured While adding referrals Details", err);

    }

  };



  const selectChange = (event) => {
    const frequency = event.target.value;
    const startDate = new Date(new Date().setDate(new Date().getDate() - frequency));
    const endDate = new Date();

    setValue("startDate", startDate);
    setValue("endDate", endDate);
    setValue("frequency", frequency);

    // Fetch referrals with updated data
    const data = { startDate, endDate };
    fetchReferrals(data);
  };



  const fetchReferrals = async (data) => {

    try {

      if (!user?.id) return;

      setloader(1);

      const { id } = user;

      const userInfoString = localStorage.getItem("app:userInfo");
      const userInfo = JSON.parse(userInfoString)
      const { hospitalId } = userInfo;

      let referralConfig = "";

      if (user?.roleId == 2) {

        referralConfig = getConfigObject(

          "post",

          `referral/listByReferrer/${id}/${hospitalId}`,

          data

        );

      } else {

        referralConfig = getConfigObject("post", `referral/list/${id}/${hospitalId}`, data);

      }

      const referralResult = await axios(referralConfig);


      const referralRecords = get(

        referralResult,

        ["data", "data", "0", "0", "referralData"],

        []

      );

      console.log("referral records --->", referralRecords);


      const myReferrals = JSON.parse(referralRecords)?.map((patients) => {

        return {

          patient: get(patients, ["pa", "0"], []),

          doctorName: get(

            patients,

            ["pa", "0", "r", "0", "d", "0", "q", "0", "qt", "0", "qp", "0", "pr", "0", "doctorName"],

            ""

          ),

          referralDate: patients?.referralDate,

          isReferralDetailAdded: patients?.isReferralDetailAdded,

          referralId: patients?.referralId,

          reffererName: patients?.referrerName,

          referredName: patients?.referredName,

          refferalNotes: patients?.referralNotes,

        };

      });

      setloader(0);

      console.log("referrals  -->", myReferrals);

      myReferrals ? setReferrals(myReferrals) : setReferrals([]);

    } catch (err) {

      showFailureToast("Failed to fetch Referrals.");

      console.error("Error Occurred while fetching", err);

    }

  };

  useEffect(() => {

    let data = {

      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),

      endDate: new Date(),

    };

    fetchReferrals(data);

  }, []);



  return (

    <FullLayout>

      <Box

        component="main"

        sx={{

          flexGrow: 1,

        }}

      >

        <Container maxWidth="lg">

          <Typography sx={{ mb: 3 }} variant="h4">

            Acknowledged Referrals

          </Typography>

          <Card

            sx={{ height: "100%", boxShadow: "rgb(0 0 0 / 15%) 0px 23px 45px" }}

          >

            {loader == 1 && <LinearProgress color="primary" />}

            <CardContent>

              <Box

                sx={{

                  p: "15px 30px",

                }}

              >

                <form component="form" onSubmit={handleSubmit(onSubmit)}>

                  <Box>

                    <Grid container spacing={3}>

                      <Grid item xs={12}>

                        <Typography variant="h6">Date range:</Typography>

                      </Grid>

                      <Grid item xs={12} sm={3}>

                        <Grid xs={12} sm={11} item>

                          <Typography>Select Frequency:</Typography>

                        </Grid>

                        <Grid xs={12} sm={11} item>

                          {

                            <Controller

                              control={control}

                              name="frequency"

                              defaultValue=""

                              render={({ field: { onChange, value } }) => (

                                <Select

                                  fullWidth

                                  size="small"

                                  name="frequency"

                                  value={value}

                                  onChange={(e) => selectChange(e)}

                                >

                                  <MenuItem value="30">last 30 Days</MenuItem>

                                  <MenuItem value="60">last 60 Days</MenuItem>

                                  <MenuItem value="90">last 90 Days</MenuItem>

                                </Select>

                              )}

                            />

                          }

                        </Grid>

                      </Grid>

                      <div style={divStyle}></div>

                      <Grid item xs={12} sm={3}>

                        <Grid xs={12} sm={12} item>

                          <Typography>Start Date:</Typography>

                        </Grid>

                        <Grid xs={12} sm={12} item>

                          <Controller

                            control={control}

                            name="startDate"

                            defaultValue=""

                            render={({ field: { onChange, value } }) => (

                              <BirthDatePicker

                                name="startDate"

                                value={value}

                                onChange={onChange}

                              />

                            )}

                          />

                        </Grid>

                      </Grid>

                      <Grid item xs={12} sm={3}>

                        <Grid xs={12} sm={12} item>

                          <Typography>End Date:</Typography>

                        </Grid>

                        <Grid xs={12} sm={12} item>

                          <Controller

                            control={control}

                            name="endDate"

                            defaultValue=""

                            render={({ field: { onChange, value } }) => (

                              <BirthDatePicker

                                name="endDate"

                                minDate={getValues("startDate")}

                                value={value}

                                onChange={onChange}

                              />

                            )}

                          />

                        </Grid>

                      </Grid>



                      <Grid

                        item

                        xs={12}

                        sm={2}

                        sx={{ paddingTop: "48px !important" }}

                      >

                        <Button

                          variant="contained"

                          type="submit"

                          fullWidth

                          disableRipple

                        // disabled={!isDirty}

                        >

                          Search

                        </Button>

                      </Grid>

                      <Grid item xs={12}>

                        <Typography variant="h6">Search By Patient Name (any number of characters):</Typography>

                      </Grid>

                      <Grid item xs={12} sm={5}>

                        <Controller

                          control={control}

                          name="searchString"

                          defaultValue=""

                          rules={{

                            required: {

                              value: false,

                            },

                          }}

                          render={({ field: { onChange, value } }) => <TextField size="small" fullWidth value={value}

                            onChange={(e) => {
                              onChange(e);
                              setSearchString(e.target.value);
                            }} type="search" />}

                        />

                        {errors?.searchTerm?.message || ""}

                      </Grid>

                    </Grid>

                  </Box>

                </form>

              </Box>

            </CardContent>

          </Card>



          <Box sx={{ mt: 2 }}>

            {referrals.length > 0 && (

              <ReferralList

                referrals={{ referrals, saveReferralDetails, fetchReferrals }}

                saveReferralDetails={{ saveReferralDetails }}

                searchTerm={searchString}
              />

            )}

          </Box>

        </Container>

      </Box>

    </FullLayout>

  );

};



export default ReferralSearch;

