/** @format */



import React from "react";

import Admin from "./Component/Home/Admin";

import FullLayout from "./Layout/FullLayout";



const App = () => {

  return (

    <FullLayout>

    </FullLayout>

  );

};



export default App;

