import React from 'react';

const AuthErrorBox = (props) => {
  const { errorMessage, errorTitle, onClear } = props;

  if (!errorMessage) {
    return null;
  }

  return (
    <div className="d-flex auth-error-box">
      <div className="align-self-center p-2">
      </div>
      <div className="p-2">
        <h6 className="font-weight-bold">{errorTitle}</h6>
        <div className="error-text text-wrap">{errorMessage}</div>
      </div>
    </div>
  );
};

AuthErrorBox.defaultProps = {
  errorMessage: '',
  errorTitle: 'Failed',
  onClear: null,
};

export default AuthErrorBox;
