import React, { useContext, createContext, useReducer, useEffect } from "react";

import axios from "axios";

import jwt_decode from "jwt-decode";



import { useLocation, useNavigate } from "react-router-dom";

import getConfigObject from "../Util/Config";



const authContext = createContext({});

export const useAuth = () => useContext(authContext);



const initialState = {

  authenticating: false,

  isAuthenticated: false,

  user: null,

};

const userInfoKey = "app:userInfo";

const useProvideAuth = () => {

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [{ user, authenticating, isAuthenticated }, dispatch] = useReducer(

    (state, action) => {

      switch (action.type) {

        case "signIn":

          return {

            ...state,

            authenticating: false,

            isAuthenticated: true,

            user: action.user,

          };

        case "forgot":

          return {

            ...state,

            authenticating: false,

            isAuthenticated: true,

            user: action.user,

          };

        case "signOut":

          return {

            ...state,

            authenticating: false,

            isAuthenticated: false,

            refreshTimeout: null,

          };

        case "authenticating":

          return { ...state, isAuthenticated: false, authenticating: true };

        default:

          return { ...state };

      }

    },

    initialState

  );





  const onSignInSuccess = async ({ userData }) => {

    localStorage.setItem(userInfoKey, JSON.stringify(userData));

    dispatch({ type: "signIn", user: userData });

    console.log("userData", userData);

    if (pathname === "/login") {

      if (userData?.roleId <= 1) {

        navigate("/new-referral");

      } else {

        navigate("/home");

      }

    }

  };



  const signOut = async () => {

    try {

      //await Auth.signOut(); Code to Invalidate Token;

      dispatch({ type: "signOut" });

      clearStorage();

      navigate("/");

    } catch (error) {

      throw error;

    }

  };



  useEffect(() => {


    const userData = getUserInfoFromLocalStorage();

    if (!userData) {

      if (window.location.href.includes("forgot")) {

      } else {

        signOut();

      }

      return;

    }

    /**

    Auth check for valid session.

    */

    onSignInSuccess({ userData, redirectToHome: true });

  }, []);



  const clearStorage = () => {

    localStorage.clear();

  };



  const signIn = async (formData) => {

    try {

      const referralConfig = getConfigObject("post", "auth/login", formData);

      const referralUser = await axios(referralConfig);

      if (referralUser?.data?.auth) {

        const userData = jwt_decode(referralUser?.data?.token);

        localStorage.setItem("token", referralUser?.data?.token);

        await onSignInSuccess({ userData, redirectToHome: true });

      }

    } catch (err) {

      throw err;

    }

  };



  return {

    user,

    isAuthenticated,

    signIn,

    signOut,

    authenticating,

  };

};



const getUserInfoFromLocalStorage = () => {

  try {

    return JSON.parse(localStorage.getItem(userInfoKey));

  } catch (err) {

    return null;

  }

};



export function ProvideAuth({ children }) {

  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;

}

