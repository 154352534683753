/** @format */



import React from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import { Grid } from "@mui/material";

import { Link, Tooltip } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { APIEndpoint } from "../../Util/Config";

import axios from "axios";

import download from "../../../assets/download.png";

import toothxrayicon from "../../../assets/toothxrayicon.jpg";

import pdficon from "../../../assets/pdficon.png";

import moment from "moment";

import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';


// Define the styles for the document
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#D3D3D3',  // Standard Grey

  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 5,
    borderBottom: '1px solid black',  // Underline effect using borderBottom
  },
  value: {
    fontSize: 12,
    marginBottom: 5,
  },
  list: {
    fontSize: 12,
    marginLeft: 10,
  },
  listItem: {
    fontSize: 12,
  }
});


const style = {

  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  width: "40%",

  height: "80%",

  bgcolor: "background.paper",

  boxShadow: 24,

  borderRadius: "8px",

  p: 4,

  display: "flex",

  flexDirection: "column",

  overflow: "auto",



  "&::WebkitScrollbarTrack": {

    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",

    backgroundColor: "#fec90f",

  },

  "&::-webkit-scrollbar": {

    width: "4px",

    backgroundColor: "#922E2E",

  },

  "&::WebkitScrollbarThumb": {

    backgroundColor: "rgba(255,255,255,.04)",

  },

};



export default function ViewReferral(props) {

  const { open, handleClose, patientInformation } = props;

  patientInformation.files = props.patientInformation.files.filter(

    (arr, index, self) =>

      index === self.findIndex((t) => t.save === arr.save && t.id === arr.id)

  );

  const referralDataPDF = {
    name: patientInformation?.firstName + ' ' + patientInformation?.lastName,
    referralSource: patientInformation.reffererName,
    studyDoctor: patientInformation.doctorName,
    email: patientInformation.email,
    phoneNumber: patientInformation.mobile,
    dob: patientInformation.dob,
    referralDate: patientInformation.referralDate,
    attachedDocuments: patientInformation.files,
    referralNotes: patientInformation.notes
  };

  console.log("patient info", patientInformation);

  // Component to generate the PDF
  const ReferralPDF = ({ referralDataPDF }) => {
    return (
      <Document>
        <Page style={styles.page}>
          <Text style={styles.title}>Referral Information</Text>

          {/* Name */}
          <View style={styles.section}>
            <Text style={styles.label}>Name:</Text>
            <Text style={styles.value}>{referralDataPDF.name}</Text>
          </View>

          {/* Referral Source */}
          <View style={styles.section}>
            <Text style={styles.label}>Referral Source:</Text>
            <Text style={styles.value}>{referralDataPDF.referralSource}</Text>
          </View>

          {/* Doctor Doing the Study */}
          <View style={styles.section}>
            <Text style={styles.label}>Doctor:</Text>
            <Text style={styles.value}>{referralDataPDF.studyDoctor}</Text>
          </View>

          {/* Contact Info */}
          <View style={styles.section}>
            <Text style={styles.label}>Email:</Text>
            <Text style={styles.value}>{referralDataPDF.email}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.label}>Phone Number:</Text>
            <Text style={styles.value}>{referralDataPDF.phoneNumber}</Text>
          </View>

          {/* Patient Info */}
          <View style={styles.section}>
            <Text style={styles.label}>Date of Birth:</Text>
            <Text style={styles.value}>{referralDataPDF.dob}</Text>
          </View>

          {/* Referral Date */}
          <View style={styles.section}>
            <Text style={styles.label}>Referral Date:</Text>
            <Text style={styles.value}>{referralDataPDF.referralDate}</Text>
          </View>

          {/* Referral Notes */}
          <View style={styles.section}>
            <Text style={styles.label}>Referral Notes:</Text>
            <Text style={styles.value}>{referralDataPDF.referralNotes}</Text>
          </View>

          {/* Attached Documents List */}
          <View style={styles.section}>
            <Text style={styles.label}>Attached Documents:</Text>
            <View style={styles.list}>
              {referralDataPDF.attachedDocuments && referralDataPDF.attachedDocuments.length > 0 ? (
                referralDataPDF.attachedDocuments.map((file, index) => (
                  // Check if the file has a valid name before rendering
                  <Text key={index} style={styles.listItem}>
                    - {file.fileName || 'Unnamed document'} {/* If fileName is undefined, use a fallback text */}
                  </Text>
                ))
              ) : (
                <Text style={styles.listItem}>No attached documents.</Text> // Fallback message if no files exist
              )}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  // Generate and download the PDF when the button is clicked
  const generatePDF = () => {
    const blob = pdf(<ReferralPDF referralDataPDF={referralDataPDF} />).toBlob();
    blob.then((pdfBlob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = 'referral-information.pdf';
      link.click();
    });
  };

  const DownloadPDF = async () => {

    let fileName =

      patientInformation.referredName +

      "_" +

      patientInformation.firstName +

      ".pdf";

    try {

      const url = `questions/documents/link` + patientInformation.documentId;

      const serverUrl = `${APIEndpoint}${url}`;

      // const res = await fetch(serverUrl);



      const res = await axios(serverUrl);

      console.log(res.data.data);

      const link = document.createElement("a");

      link.href = res.data.data;

      link.setAttribute("download", fileName);



      // Append to html link element page

      document.body.appendChild(link);



      // // Start download

      link.click();

      // // Clean up and remove the link

      link.parentNode.removeChild(link);

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };



  const downloadDocumentPDF = async () => {

    let fileName = "patient.pdf";

    try {

      const url = `questionsDocument/d5872d1f740e42c1969ac8e0da732c1ba34c42cc`;

      const serverUrl = `${APIEndpoint}${url}`;

      const response = await fetch(serverUrl);



      const pdfBlob = new Blob([response.data], { type: "application/pdf" });



      const blobUrl = window.URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");

      link.href = blobUrl;

      link.setAttribute("download", fileName);

      link.click();

      link.remove();

      URL.revokeObjectURL(blobUrl);

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };



  const downloadDocument = async (documentId, fileName) => {

    try {

      const url = `patients/documents/${documentId}`;

      const serverUrl = `${APIEndpoint}${url}`;

      const res = await fetch(serverUrl);

      const blob = await res.blob();

      const urlDownload = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");

      link.href = urlDownload;

      link.setAttribute("download", fileName);



      // Append to html link element page

      document.body.appendChild(link);



      // Start download

      link.click();

      // Clean up and remove the link

      link.parentNode.removeChild(link);

    } catch (err) {

      console.error("failed to fetch doctors record", err);

    }

  };



  const getQuestionAnswer = () => {

    return (

      <Grid item xs={12}>

        {patientInformation?.mappedQuestionAnswers

          ?.filter((q) => q?.answers !== "")

          ?.map((patient) => {

            return (

              <Grid container mb={2} alignItems="center">

                <Grid item xs={12}>

                  <Typography variant="Subtitle">{`Q. ${patient?.question}`}</Typography>

                </Grid>

                <Grid item xs={12}>

                  <Typography variant="body2">{`A. ${patient?.answers}`}</Typography>

                </Grid>

              </Grid>

            );

          })}

        {/* <Grid sx={{ 'aria-label': 'Switch demo' }}>

                    Attached Documents

                </Grid> */}

      </Grid>

    );

  };



  return (

    <div>

      <Modal

        open={open}

        onClose={handleClose}

        aria-labelledby="modal-modal-title"

        aria-describedby="modal-modal-description"

      >

        <Box sx={style}>

          <Grid container spacing={2} alignItems="center">

            <Grid item xs={12} md={10}>

              <Typography variant="h4">Referral Information</Typography>

            </Grid>

            <Grid item xs={12} md={2}>

              <Button variant="contained" onClick={handleClose}>

                Close

              </Button>

            </Grid>

          </Grid>

          <Grid container alignItems="center" sx={{ overflow: "auto" }}>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2} mt={1}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Name:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">{`${patientInformation?.firstName}  ${patientInformation?.lastName}`}</Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2} mt={1}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Referral Source:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">

                    {patientInformation.reffererName}

                  </Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2} mt={1}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Doctor:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">

                    {patientInformation.doctorName}

                  </Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2} mt={1}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Email:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">

                    {patientInformation.email}

                  </Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2} mt={1}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Dob:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">

                    {patientInformation.dob

                      ? moment(patientInformation.dob).format("DD/MMM/yyyy")

                      : "--/--/----"}

                  </Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2} mt={1}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Phone:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">

                    {patientInformation.mobile}

                  </Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Referral Date:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">{`${patientInformation?.referralDate}`}</Typography>

                </Grid>

              </Grid>

            </Grid>



            <Grid item xs={9}>

              <Grid container alignItems="center" mb={2}>

                <Grid item xs={6} md={6}>

                  <Typography variant="Subtitle">Notes:</Typography>

                </Grid>

                <Grid item xs={6} md={6}>

                  <Typography variant="body2">{`${patientInformation?.notes}`}</Typography>

                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={9} spacing={3}>

              <Typography variant="h6">

                Attached Documents

                <br />

              </Typography>

              {patientInformation.fileUrl

                ? "File url :" + patientInformation.fileUrl

                : ""}

              {patientInformation.documentId &&

                patientInformation.documentId != "manualentry" ? (

                <Link

                  sx={{ cursor: "pointer" }}

                  style={{ display: "block" }}

                  onClick={() => DownloadPDF()}

                >

                  <Tooltip title="Patient.pdf" sx={{ marginBottom: "-6px" }}>

                    <img src={pdficon} alt="download" />

                  </Tooltip>

                  <span> Patient.pdf </span>

                </Link>

              ) : (

                ""

              )}

              <Grid

                container

                alignItems="center"

                style={{ "margin-top": "0px" }}

                mb={2}

                spacing={2}

                columns={1}

              >

                {!patientInformation?.files?.filter(

                  (f) => !f?.description && f?.fileName

                )?.length && (

                    <Grid item xs={6} md={6}>

                      No Attachments

                    </Grid>

                  )}

                {patientInformation?.files

                  ?.filter((f) => !f.description && f?.fileName)

                  .map((file) => (

                    <Grid item xs={6} md={6}>

                      <Link

                        sx={{ cursor: "pointer" }}

                        onClick={() =>

                          downloadDocument(file?.id, file?.fileName)

                        }

                      >

                        <Tooltip

                          title={file?.fileName}

                          sx={{ marginBottom: "-6px" }}

                        >

                          <img

                            src={toothxrayicon}

                            alt="download"

                            style={{ width: "4%" }}

                          />

                        </Tooltip>

                        &nbsp;

                        <span>{`${file?.fileName}`}</span>

                      </Link>

                    </Grid>

                  ))}

              </Grid>

            </Grid>

            {getQuestionAnswer()}

            <Button variant="contained" onClick={generatePDF}>Download Referral PDF</Button>


          </Grid>


        </Box>

      </Modal>

    </div>

  );

}

